const loadItem = (project, pk, settings = {}) => {
    settings['setToModel'] = false
    project.passwordDialog.dialog = false
    return project._loadItem(pk, settings).then(res => {
        res.decrypted = false
        res.password_groups.forEach(val => {
            val.hidden = res.password_groups.length > 1
            val.passwords.forEach(pass => {
                pass.decrypted = ''
                pass.decrypted_description = ''
            })
        })
        res.notes.forEach(val => {
            val.decrypted = ''
        })
        res.child_projects.forEach(val => {
            val.decrypted = ''
        })
        res.passwords = []
        project.item = res
        project.unlock()
    })
}

module.exports = loadItem