<template>
    <v-col class="pa-0">
        <div class="font-weight-medium pb-3 px-3">Доступы</div>
        <v-checkbox hide-details class="ma-0 pa-0 mb-2 mx-2" v-model="object.rights"
                    v-for="el in $store.state.items.right.list" :label="el.title" :value="el.value"
                    :key="el.value"></v-checkbox>

        <v-layout column class="ma-0" v-if="object.rights.indexOf(4) === -1">
            <v-layout class="ma-0 pr-2" align-center justify-space-between>
                <div class="primary--text px-3" style="cursor: pointer"
                     @click="showPasswords = !showPasswords">
                    Доступные пароли (выбрано {{object.passwords.length}} из
                    {{$store.state.items.project.item.passwords.length}})
                </div>
                <search max-width="170" v-model="search"></search>
            </v-layout>
            <v-layout column class="ma-0 mt-2" v-if="showPasswords">
                <div style="width: 100%; max-height: 200px; overflow-y: auto" class="py-1">
                    <v-checkbox hide-details class="ma-0 pa-0 mb-2 mx-2"
                                v-for="el in passwordListBySearch"
                                v-model="object.passwords"
                                :label="el.name"
                                :value="el.id" :key="el.id">
                    </v-checkbox>
                </div>
            </v-layout>
        </v-layout>
    </v-col>
</template>

<script>
    import Search from "./Search";

    export default {
        name: "SelectRights",
        components: {
            Search,
        },
        props: {
            value: Object
        },
        data: () => ({
            object: null,
            showPasswords: false,
            search: ''
        }),
        created() {
            this.object = this.value
        },
        methods: {},
        computed: {
            passwordListBySearch() {
                if (!this.search) return this.$store.state.items.project.item.passwords;
                let search = this.search.toLowerCase().split(' ');
                return this.$store.state.items.project.item.passwords.filter(value => {
                    let text = value.name.toLowerCase();
                    let i = true;
                    search.forEach(value => {
                        if (text.indexOf(value) === -1)
                            i = false;
                    });
                    return i;
                });
            }
        }
    }
</script>

<style scoped>

</style>