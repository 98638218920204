<template>

    <v-row no-gutters align="center">
        <v-col cols="12" md="">
            <v-row no-gutters align="center">
                <v-row no-gutters align="center" justify="center" style="max-width: 50px">
                    <air-icon class="grey--text text--darken-2" size="40" icon="cctv"></air-icon>
                </v-row>
                <v-col class="mx-5">
                    <div style="font-weight: 600">Сессии</div>
                    <div class="caption grey--text text--darken-2">Текущие авторизованный сессии</div>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" md="auto" class="mt-3 mt-md-0">
            <air-button @click="dialog = true" floating :loading="loading">Посмотреть
                {{ !loading ? this.$session.list.length : '' }}
            </air-button>
            <air-dialog v-model="dialog" title="Сессии">
                <v-col v-for="(el, index) in list" :key="index" class="pa-0">
                    <air-divider v-if="index" class="my-3"></air-divider>
                    <v-row no-gutters align="center">
                        <div>{{ el.os }}</div>
                        <div class="ml-2 caption grey--text">{{ el.os_version }}</div>
                        <v-spacer></v-spacer>
                        <div class="body-2">{{parseDate(el.updated_at)}}</div>
                    </v-row>
                    <v-row no-gutters align="center" class="mt-0" v-if="el.browser">
                        <div>{{ el.browser }}</div>
                        <div class="ml-2 caption grey--text">{{ el.browser_version }}</div>
                    </v-row>
                    <div class="caption grey--text">{{el.ip}}</div>
                </v-col>
            </air-dialog>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'Sessions',
    data: () => ({
        dialog: false,
        loading: false
    }),
    methods: {
        parseDate(date) {
            return moment.calendarDateTime(date)
        }
    },
    created() {
        this.loading = true
        this.$session.loadList().then(() => {
            this.loading = false
        })
    },
    computed: {
        list() {
            return this.$session.list
        }
    }
}
</script>

<style scoped>

</style>