<template>
    <auth-view title="Авторизация">
        <air-form v-model="valid">
            <air-text-field
                :rules="[$rules.required]"
                v-model="username" placeholder="Логин" icon="signature"></air-text-field>
            <air-text-field
                :rules="[$rules.required]"
                v-model="password" type="password" placeholder="Пароль" icon="key"
                class="mt-5"></air-text-field>
        </air-form>
        <v-row no-gutters align="center" justify="center" class="flex-column">
            <air-button
                block
                :loading="loading" @click="login"
                :disabled="!valid"
                class="mt-7" gradient floating style="min-width: 200px">Войти
            </air-button>
            <router-link :to="{name: 'register'}">
                <div class="mt-3 body-2">Регистрация</div>
            </router-link>
        </v-row>

        <vs-dialog v-model="authenticatorDialog">
            <v-col class="pa-0 mt-5 px-5">
                <v-row no-gutters align="center" justify="center" class="flex-column">
                    <div class="mb-5 font-weight-bold">Ввод Google аутентификации</div>
                </v-row>
                <authenticator-input @complete="setAuthenticator"></authenticator-input>
                <div class="mt-3 caption grey--text text--darken-2">Введите 6-значный код из приложения Google
                    Authenticator.
                </div>
            </v-col>
        </vs-dialog>
    </auth-view>
</template>

<script>
import AuthView from "@/views/auth/AuthView";
import AuthenticatorInput from "@/components/AuthenticatorInput";

export default {
    name: "Login",
    components: {
        AuthView,
        AuthenticatorInput
    },
    data: () => ({
        username: '',
        password: '',
        loading: false,
        authenticator: null,
        authenticatorDialog: false,
        valid: false
    }),
    methods: {
        setAuthenticator(val) {
            this.authenticator = val
            this.login()
        },
        login() {
            this.loading = true
            this.$user.getUserUuid(this.username).then(result => {
                let credentials = this.$project.generateCredentials(this.password, result.uuid)
                this.$auth.login({
                    username: this.username,
                    password: credentials.hash,
                    authenticator: this.authenticator
                }).then(() => {
                    this.loading = false
                    localStorage.setItem(credentials.uuid, credentials.password)
                    this.$router.push({
                        name: 'projectList'
                    })
                }).catch(e => {
                    this.loading = false
                    if (e.data.authenticator) {
                        if (this.authenticatorDialog)
                            this.$snackbar.fail('Неверный код аутентификации')
                        this.authenticatorDialog = true
                    } else {
                        this.$snackbar.fail('Неверные данные авторизации')
                    }
                })
            }).catch(() => {
                this.$snackbar.fail('Неверные данные авторизации')
                this.loading = false
            })
        }
    },
    created() {
        this.$auth.me()
    }
}
</script>

<style scoped>

</style>