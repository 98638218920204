<template>
    <v-list dense class="primary">
        <v-list-item @click="" :to="{name: 'userInfo'}">
            <v-list-item-title>
                <v-row no-gutters align="center" class="flex-nowrap">
                    <air-icon icon="user" class="white--text"></air-icon>
                    <span class="ml-3 white--text">Пользователь</span>
                </v-row>
            </v-list-item-title>
        </v-list-item>
        <v-list-item @click="" :to="{name: 'userInvites'}">
            <v-list-item-title>
                <v-row no-gutters align="center" class="flex-nowrap">
                    <air-icon icon="paper-plane" class="white--text"></air-icon>
                    <span class="ml-3 white--text">
                                Приглашения
                            <span class="font-weight-bold"
                                  v-if="$auth.user.came_project_invites.length > 0">
                                    ({{ $auth.user.came_project_invites.length }})
                                </span>
                            </span>
                </v-row>
            </v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
            <v-list-item-title>
                <v-row no-gutters align="center" class="flex-nowrap">
                    <air-icon icon="share-square" class="white--text"></air-icon>
                    <span class="ml-3 white--text">Выйти</span>
                </v-row>
            </v-list-item-title>
        </v-list-item>
    </v-list>
</template>

<script>
export default {
    name: 'UserMenuList',
    data: () => ({}),
    methods: {
        logout() {
            this.$auth.logout()
            this.$router.push({name: 'login'})
        }
    }
}
</script>

<style scoped>

</style>