const m = require('air-vue-model/model')()
m.url = 'project_users'
m.name = 'projectUser'

m.dialog = false
m.openDialog = projectUser => {
    m.item = m.copy(projectUser)
    m.dialog = true
}

module.exports = m