<template>

</template>

<script>
export default {
    name: "Snackbar",
    watch: {
        '$snackbar.value'(val) {
            if (val) {
                this.$vs.notification({
                    color: this.snackbar.color,
                    position: this.snackbar.position,
                    title: this.snackbar.title,
                    text: this.snackbar.text
                })
            }
        }
    },
    computed: {
        snackbar() {
            return this.$snackbar
        }
    }
}
</script>

<style scoped>

</style>