<template>
    <v-layout column class="ma-0">
        <app-bar></app-bar>
        <v-container class="pt-0">
            <router-view></router-view>
        </v-container>
    </v-layout>
</template>

<script>

import AppBar from "@/views/appBar/AppBar";

export default {
    name: 'Home',
    components: {
        AppBar
    },
    data: () => ({}),
    computed: {},
    created() {

    }
}
</script>

<style>

</style>
