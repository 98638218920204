const m = require('air-vue-model/model')()
m.url = 'project_invites'
m.name = 'invite'

m.setPagination({
    page_size: 'all'
})

m.default = {
    to_user: null,
    project: null,
    rights: [],
    admin: false
}

module.exports = m