<template>
    <v-app-bar app flat height="56" color="primary">
        <v-layout class="ma-0" align-center>
            <router-link :to="{name: 'projectList'}">
                <v-layout align-center>
                    <air-icon class="mr-3 white--text" icon="key"></air-icon>
                    <div class="title white--text">repass</div>
                </v-layout>
            </router-link>
            <v-spacer></v-spacer>
            <user-menu v-if="$auth.user" class="px-2"></user-menu>
        </v-layout>
    </v-app-bar>
</template>

<script>
import UserMenu from "./UserMenu";

export default {
    name: 'AppBar',
    components: {
        UserMenu,
    },
    data: () => ({}),
    methods: {}
}
</script>

<style scoped>

</style>