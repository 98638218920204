<template>
    <v-col class="pa-0">
        <air-list-template
            v-if="items.length"
            :items="items" v-model="$invite.filters.search" :search-function="(val) => val.project.name">
            <template v-slot:item="{item, index}">
                <v-row align="center" class="pa-1" no-gutters>
                    <v-col class="body-2">
                        <v-row no-gutters align="center">
                            <div class="grey--text text--darken-1 mr-1">Проект:</div>
                            <span class="font-weight-bold">{{ item.project.name }}</span>
                        </v-row>
                        <div class="">{{ item.from_user.username }}</div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <air-button @click="acceptInvite(item, index)" floating>Принять</air-button>
                    <air-button @click="declineInvite(item, index)" floating danger>Отклонить</air-button>
                </v-row>
            </template>
        </air-list-template>
        <v-col class="pa-0">
            <v-row class="ma-0 body-2 grey--text text--darken-2" align="center" v-if="!items.length">
                У вас нет новых приглашений
            </v-row>
        </v-col>
    </v-col>
</template>

<script>

import AirListTemplate from "@/components/template/AirListTemplate";

export default {
    name: "Invites",
    components: {
        AirListTemplate
    },
    computed: {
        items() {
            return this.$auth.user.came_project_invites
        },
    },
    methods: {
        acceptInvite(el, index) {
            this.$invite.sendPostSingle('accept_invite', el.id).then(() => {
                this.items.splice(index, 1)
                this.$router.push({
                    name: 'projectPasswords',
                    params: {
                        projectId: el.project.id
                    }
                })
            })
        },
        declineInvite(el, index) {
            this.$invite.destroy(el.id).then(() => {
                this.items.splice(index, 1)
            })
        }
    }
}
</script>

<style scoped>

</style>