<template>
    <v-row no-gutters align="center" style="overflow-x: auto; max-width: 100%" class="flex-nowrap">
        <div v-for="(el, index) in items" :key="index">
            <v-row no-gutters align="center" style="min-height: 50px"
                   class="flex-nowrap py-2">
                <air-divider vertical v-if="index" class="mx-4"></air-divider>
                <action-button
                    :count="el.lengthKey ? $project.item[el.lengthKey].length : null"
                    :active="$route.name === el.to"
                    :to="{name: el.to}" :icon="el.icon">{{ el.title }}
                </action-button>
            </v-row>
        </div>
    </v-row>
</template>

<script>
export default {
    name: "ProjectActions",
    data: () => ({}),
    methods: {},
    computed: {
        items() {
            let results = []
            results.push({title: 'Пароли', icon: 'key', to: 'projectPasswords', lengthKey: 'passwords'})
            results.push({title: 'Заметки', icon: 'signature', to: 'projectNotes', lengthKey: 'notes'})
            if (this.$project.item.personal)
                results.push({
                    title: 'Проекты',
                    icon: 'project-diagram',
                    to: 'projectChildProjects',
                    lengthKey: 'child_projects'
                })
            if (!this.$project.item.personal)
                results.push({title: 'Пользователи', icon: 'user', to: 'projectUsers'})
            results.push({title: 'Настройки', icon: 'cogs', to: 'projectSettings'})
            return results
        }
    }
}
</script>

<style scoped>

</style>