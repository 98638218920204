<template>
    <v-col class="pa-0">
        <air-button
            v-if="!project.personal"
            transparent danger block @click="dialog = true">Выйти из проекта
        </air-button>
        <air-dialog v-model="dialog"
                    description="Введите наименование проекта для подтверждения"
                    title="Подтверждение выхода">
            <air-text-field placeholder="Наименование проекта" v-model="confirmName"
                            icon="signature"></air-text-field>
            <air-button class="mt-5" floating
                        block
                        @click="exitFromProject" :disabled="confirmName !== project.name">Выйти из проекта
            </air-button>
        </air-dialog>
    </v-col>
</template>

<script>
export default {
    name: 'ExitFromProject',
    props: {},
    data: () => ({
        confirmName: null,
        dialog: false
    }),
    watch: {
        dialog(val) {
            if (val) {
                this.confirmName = null
            }
        }
    },
    computed: {
        project() {
            return this.$project.item
        }
    },
    methods: {
        exitFromProject() {
            this.dialog = true
            const currentProjectUser = this.$project.currentProjectUser
            this.$projectUser.destroy(currentProjectUser.id).then(() => {
                this.$snackbar.success('Вы вышли из проекта')
                this.$router.replace({
                    name: 'projectList',
                })
            }).catch(() => {
                this.$snackbar.fail('Не удалось выйти из проекта')
            })
        }
    }
}
</script>

<style scoped>

</style>