<template>
    <router-view></router-view>
</template>

<script>

    export default {
        name: "Notes",
        data: () => ({}),

    }
</script>

<style scoped>

</style>