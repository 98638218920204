const m = require('air-vue-model/model')()
m.url = 'users'
m.name = 'user'

m.getUserUuid = username => m.sendGet('get_user_uuid', {username})
m.updateAvatar = avatar => m.sendPost('update_avatar', {avatar})

m.disableAuthenticator = authenticator => {
    return m.sendPost('disable_authenticator', {authenticator})
}
m.setAuthenticator = () => {
    return m.sendPost('set_authenticator')
}
m.enableAuthenticator = (authenticator) => {
    return m.sendPost('enable_authenticator', {authenticator})
}


m.routes = [
    {
        name: 'profile',
        path: 'profile',
        component: require('@/views/user/Profile'),
        children: [
            {
                name: 'invites',
                path: 'invites',
                component: require('@/views/user/Invites')
            },
            {
                name: 'info',
                path: 'info',
                component: require('@/views/user/UserInfo')
            },
            {
                name: 'security',
                path: 'security',
                component: require('@/views/user/Security')
            },
        ]
    },
]

module.exports = m
