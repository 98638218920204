<template>
    <v-col class="ma-0 pa-0">
        <slot></slot>
    </v-col>
</template>

<script>
export default {
    name: 'AirForm',
    props: {
        value: Boolean
    },
    data: () => ({
        mounted: false
    }),
    methods: {
        validateComponents(components) {
            if (!components || !components.length)
                return true
            for (const el of components) {
                if (el.valid === false)
                    return false
                if (!this.validateComponents(el.$children))
                    return false
            }
            return true
        }
    },
    computed: {
        valid() {
            if (this.mounted) {
                if (this.$slots.default) {
                    const values = this.$slots.default.filter(val => val.componentInstance)
                    return this.validateComponents(values.map(val => val.componentInstance))
                }
                return true
            }
        },
    },
    mounted() {
        this.mounted = true
    },
    watch: {
        valid(val) {
            this.$emit('input', val)
        }
    }
}
</script>

<style scoped>

</style>