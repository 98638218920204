const m = require('air-vue-model/model')()
m.url = 'project_groups'
m.name = 'projectGroup'

m.setPagination({
    page_size: 'all'
})

m.default = {
    name: '',
    icon: 'layer-group',
    sorting: 1
}
m.dialog = false
m.loaded = false

m.loadList = (params, settings) => {
    m._loadList(params, {
        setToModel: false
    }).then(res => {
        const currentIds = []
        let openProjectGroups = m.getOpenProjectGroups()
        res.results.forEach(val => {
            currentIds.push(val.id)
            val.hidden = openProjectGroups.indexOf(val.id) <= -1
        })
        openProjectGroups = openProjectGroups.filter(val => currentIds.indexOf(val) > -1)
        m.setOpenProjectGroups(openProjectGroups)
        m.list = res.results
        m.loaded = true
    })
}

m.getOpenProjectGroups = () => {
    let openProjectGroups = localStorage.getItem('openProjectGroups')
    if (!openProjectGroups) {
        openProjectGroups = []
    } else {
        openProjectGroups = JSON.parse(openProjectGroups)
    }
    return openProjectGroups
}

m.setOpenProjectGroups = (openProjectGroups) => {
    localStorage.setItem('openProjectGroups', JSON.stringify(openProjectGroups))
}

m.openDialog = (projectGroup = null) => {
    m.item = m.copy(projectGroup || m.default)
    setTimeout(() => {
        m.dialog = true
    }, 50)
}


module.exports = m