<template>
    <!--    <div :style="`font-size: ${size}px`" class="transition-fast-in-fast-out">-->
    <i :style="`font-size: ${size}px`"
       :class="_icon"
       class="transition-fast-in-fast-out"></i>
    <!--    <div>{{_icon}}</div>-->
    <!--                    <font-awesome-icon-->
    <!--                        v-if="currentIcon" :icon="currentIcon"/>-->
    <!--    </div>-->
</template>

<script>
export default {
    name: 'AirIcon',
    props: {
        icon: String,
        size: {
            type: [String, Number],
            default: 13
        }
    },
    data: () => ({}),
    methods: {},
    created() {

    },
    computed: {
        _icon() {
            if (!this.icon)
                return null
            return this.icon.indexOf(' ') > -1 ? this.icon : `fas fa-${this.icon}`
        },
        currentIcon() {
            if (!this.icon)
                return null
            const splitValues = this.icon.split(' ')
            if (splitValues.length > 1)
                return splitValues
            return this.icon
        }
    }
}
</script>

<style scoped>

</style>