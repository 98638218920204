<template>
    <v-col class="pa-0">
        <air-list-template :items="item.project_users">
            <template v-slot:actions>
                <v-spacer></v-spacer>
                <air-button v-if="$project.currentProjectUserAdmin" @click="inviteDialog = true" floating>Пригласить
                </air-button>
                <invite-user v-model="inviteDialog"></invite-user>
            </template>
            <template v-slot:item="{item}">
                <v-row no-gutters align="center">
                    <v-img max-width="35" height="35" :src="item.user.avatar"></v-img>
                    <div class="px-3">{{ item.user.username }}</div>
                    <air-icon v-if="item.admin" icon="crown" class="orange--text"></air-icon>
                    <v-spacer></v-spacer>
                    <air-button @click="$projectUser.openDialog(item)"
                                v-if="item.user.id !== $auth.user.id && $project.currentProjectUserAdmin"
                                icon="ellipsis-v" transparent></air-button>
                    <project-user-dialog v-model="$projectUser.dialog"></project-user-dialog>
                </v-row>
            </template>
        </air-list-template>
        <air-description-text class="my-3" v-if="item.project_invites.length">Ожидают подтверждения
        </air-description-text>
        <air-list-template :items="item.project_invites" hide-actions>
            <template v-slot:item="{item, index}">
                <v-row no-gutters align="center">
                    <v-img max-width="35" height="35" :src="item.to_user.avatar"></v-img>
                    <div class="px-3">{{ item.to_user.username }}</div>
                    <air-icon icon="crown" class="orange--text" v-if="item.admin"></air-icon>
                    <v-spacer></v-spacer>
                    <air-button icon="times" transparent @click="deleteInvite(item, index)"></air-button>
                </v-row>
            </template>
        </air-list-template>
    </v-col>
</template>

<script>
import InviteUser from "../../../../components/InviteUser";
import crypto from "../../../../mixins/crypto";
import AwaitingInvites from "../../../../components/AwaitingInvites";
import SelectRights from "../../../../components/SelectRights";
import AirListTemplate from "@/components/template/AirListTemplate";
import ProjectUserDialog from "@/views/projects/item/users/ProjectUserDialog";

export default {
    name: "ProjectUsers",
    components: {
        InviteUser,
        AwaitingInvites,
        SelectRights,
        AirListTemplate,
        ProjectUserDialog
    },
    mixins: [
        crypto
    ],
    data: () => ({
        selectedProjectUser: null,
        inviteDialog: false,
    }),
    methods: {
        deleteInvite(item, index) {
            this.$invite.destroy(item.id).then(() => {
                this.$snackbar.success('Приглашение удалено')
            })
            this.item.project_invites.splice(index, 1)
        }
    },
    computed: {
        item() {
            return this.$project.item
        }
    }
}
</script>

<style scoped>

</style>