<template>
    <v-col class="pa-0">
        <air-list-template
            v-if="$project.hasRight('VIEW_PASSWORDS')"
            v-model="$password.filters.search"
            :items="$project.item.password_groups" child-field="passwords">
            <template v-slot:actions v-if="$project.hasRight('EDIT_PASSWORDS')">
                <v-spacer></v-spacer>
                <air-button floating @click="$password.openDialog">Создать</air-button>
                <air-button floating icon="folder-plus" @click="$passwordGroup.openDialog"></air-button>
            </template>
            <template v-slot:item="{item}">
                <v-row no-gutters align="center">
                    <v-row class="pointer noselect" no-gutters align="center" @click="item.hidden = !item.hidden">
                        <air-icon :icon="item.icon" class="mr-3 primary--text"></air-icon>
                        <div>{{ item.name }}</div>
                        <div class="ml-2 caption grey--text">{{ item.passwords.length }}</div>
                        <air-icon :icon="!item.hidden ? 'caret-up' : 'caret-down'"
                                  class="ml-2 grey--text"></air-icon>
                    </v-row>
                    <air-button
                        v-if="item.id"
                        icon="ellipsis-v"
                        @click="item.id ? $passwordGroup.openDialog(item) : null"
                        class="mx-0 my-0"
                        transparent></air-button>
                </v-row>
            </template>
            <template v-slot:child="{child}">
                <password :item="child"></password>
            </template>
        </air-list-template>
        <v-row v-else no-gutters align="center" justify="center" class="mt-10">
            <v-col cols="10" sm="6" md="5" lg="5">
                <v-row no-gutters align="center" justify="center" class="flex-column">
                    <v-img src="/static/images/tree.svg"></v-img>
                    <div class="mt-5 body-2 grey--text text--darken-2">У вас нет доступа к паролям</div>
                </v-row>
            </v-col>
        </v-row>
        <password-dialog></password-dialog>
        <password-group-dialog></password-group-dialog>
    </v-col>
</template>

<script>
import Password from "./listElement/Password"
import PasswordsActions from "@/views/projects/item/passwords/PasswordsActions";
import AirListTemplate from "@/components/template/AirListTemplate";
import PasswordDialog from "@/views/projects/item/passwords/PasswordDialog";
import PasswordGroupDialog from "@/views/projects/item/passwords/passwordGroup/PasswordGroupDialog";

export default {
    name: "Passwords",
    components: {
        PasswordsActions,
        AirListTemplate,
        PasswordDialog,
        Password,
        PasswordGroupDialog
    },
    data: () => ({}),
    computed: {},
    methods: {},

}
</script>

<style scoped>
.scroller {
    height: 300px;
    overflow-y: auto;
}

.scroller-item {
    display: flex;
    min-height: 32px;
    padding: 12px;
    box-sizing: border-box;
}
</style>