<template>
    <v-hover v-slot="{ hover }">
        <div style="width: min-content">
            <router-link replace :to="to">
                <v-row
                    no-gutters align="center" class="flex-nowrap caption unselect">
                    <air-icon :size="active ? 20 : 15"
                        :icon="icon" :class="active ? 'primary--text' : 'grey--text'" class="mr-2"></air-icon>
                    <!--                    <v-icon :color="active ? 'primary' : 'secondary'" size="20">{{ icon }}</v-icon>-->
                    <div :class="active ? 'primary--text' : hover ? 'primary--text' : 'black--text'"
                         class="transition-fast-in-fast-out">
                        <slot></slot>
                    </div>
<!--                    <v-badge-->
<!--                        v-if="count"-->
<!--                        color="primary"-->
<!--                        inline-->
<!--                        :content="count"-->
<!--                    ></v-badge>-->
                </v-row>
            </router-link>
        </div>
    </v-hover>
</template>

<script>
export default {
    name: "ActionButton",
    props: {
        active: Boolean,
        icon: {
            type: String,
            default: 'mdi-account'
        },
        count: Number,
        to: Object
    }
}
</script>

<style scoped>

</style>