<template>
    <v-row no-gutters align="center">
        <v-col cols="12" md="">
            <v-row no-gutters align="center">
                <v-row no-gutters align="center" justify="center" style="max-width: 50px">
                    <air-icon class="grey--text text--darken-2" size="40" icon="fingerprint"></air-icon>
                </v-row>
                <v-col class="mx-5">
                    <div style="font-weight: 600">Google аутентификация</div>
                    <div class="caption grey--text text--darken-2">Используется для улучшения безопасности</div>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" md="auto" class="mt-3 mt-md-0">
            <air-button :loading="setAuthenticatorLoading" floating @click="disableDialog = true"
                        v-if="user.authenticator_enable">Отключить
            </air-button>
            <air-button floating @click="setAuthenticator" v-else>Подключить</air-button>
            <vs-dialog v-model="disableDialog">
                <v-col class="pa-0 mt-5 px-5">
                    <v-row no-gutters align="center" justify="center" class="flex-column">
                        <div class="mb-5 font-weight-bold">Отключение Google аутентификации</div>
                    </v-row>
                    <authenticator-input @complete="disableComplete"></authenticator-input>
                    <div class="mt-3 caption grey--text text--darken-2">Введите 6-значный код из приложения Google
                        Authenticator.
                    </div>
                </v-col>
            </vs-dialog>
            <vs-dialog v-model="enableDialog">
                <v-col class="pa-0 mt-5 px-5">
                    <v-row no-gutters align="center" justify="center" class="flex-column">
                        <div class="mb-5 font-weight-bold">Подключение Google аутентификации</div>
                        <qrcode-vue :value="authenticatorUrl" size="250" level="H" class="mb-5"></qrcode-vue>
                    </v-row>
                    <authenticator-input @complete="enableComplete"></authenticator-input>
                    <div class="mt-3 caption grey--text text--darken-2">Введите 6-значный код из приложения Google
                        Authenticator.
                    </div>
                </v-col>
            </vs-dialog>
        </v-col>
    </v-row>
</template>

<script>
import QrcodeVue from "qrcode.vue"
import AuthenticatorInput from "@/components/AuthenticatorInput";

export default {
    name: 'Authenticator',
    components: {
        QrcodeVue,
        AuthenticatorInput
    },
    data: () => ({
        disableDialog: false,
        enableDialog: false,
        authenticator: null,
        setAuthenticatorLoading: false,
        authenticatorUrl: ''
    }),
    methods: {
        disableComplete(val) {
            this.$user.disableAuthenticator(val).then(() => {
                this.user.authenticator_enable = false
                this.disableDialog = false
                this.$snackbar.success('Google аутентификация отключена')
            })
        },
        setAuthenticator() {
            this.setAuthenticatorLoading = true
            this.$user.setAuthenticator().then(res => {
                this.authenticatorUrl = res.authenticator_url
                this.enableDialog = true
                this.setAuthenticatorLoading = false
            })
        },
        enableComplete(val) {
            this.$user.enableAuthenticator(val).then(() => {
                this.enableDialog = false
                this.user.authenticator_enable = true
            }).then(res => {
                this.$snackbar.success('Google аутентификация подключена')
            }).catch(() => {
                this.$snackbar.fail('Неверный код аутентификации')
            })
        },
    },
    computed: {
        user() {
            return this.$auth.user
        }
    }
}
</script>
