<template>
    <v-row no-gutters style="max-width: 400px">
        <air-button @click="$emit('input', el)"
                    :transparent="value !== el"
                    :active="value === el"
                    v-for="(el) in items" :key="el" :icon="el">
        </air-button>
    </v-row>
</template>

<script>
export default {
    name: 'Icons',
    props: {
        value: String
    },
    data: () => ({
        items: [
            'fab fa-bitcoin', 'user', 'key', 'eye', 'eye-slash', 'signature', 'lock',
            'layer-group', 'search', 'at', 'paper-plane', 'cogs',
            'lock-open', 'project-diagram', 'envelope', 'bell', 'share-square', 'crown', 'alien', 'ufo', 'biohazard',
            'bong', 'cannabis', 'heart', 'poop', 'virus', 'alicorn', 'cross', 'bomb', 'hand-middle-finger',
            'portal-enter', 'donate', 'info', 'turtle', 'broom', 'meteor', 'robot', 'hockey-mask', 'paw', 'spider'
        ]
    }),
    methods: {}
}
</script>

<style scoped>

</style>