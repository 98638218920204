import Vue from 'vue'

Vue.prototype.$snackbar = Vue.observable(require('./models/snackbar'))
Vue.prototype.$auth = Vue.observable(require('./models/auth'))
Vue.prototype.$project = Vue.observable(require('./models/project/project'))
Vue.prototype.$tag = Vue.observable(require('./models/tag'))
Vue.prototype.$projectUser = Vue.observable(require('./models/projectUser'))
Vue.prototype.$invite = Vue.observable(require('./models/invite'))
Vue.prototype.$session = Vue.observable(require('./models/session'))
Vue.prototype.$projectRight = Vue.observable(require('./models/projectRight'))
Vue.prototype.$crypto = Vue.observable(require('./models/crypto'))
Vue.prototype.$password = Vue.observable(require('./models/password'))
Vue.prototype.$user = Vue.observable(require('./models/user'))
Vue.prototype.$note = Vue.observable(require('./models/note'))
Vue.prototype.$avatar = Vue.observable(require('./models/avatar'))
Vue.prototype.$passwordGroup = Vue.observable(require('./models/passwordGroup'))
Vue.prototype.$projectRelation = Vue.observable(require('./models/projectRelation'))
Vue.prototype.$projectGroup = Vue.observable(require('./models/projectGroup'))
Vue.prototype.$rules = Vue.observable(require('air-vue-model/rules'))

