<template>
    <air-dialog v-if="item" :value="value" @close="$emit('input', false)" title="Работа с пользователем">
        <v-row no-gutters align="center">
            <v-img max-width="35" height="35" :src="item.user.avatar"></v-img>
            <div class="px-3">{{ item.user.username }}</div>
            <air-icon v-if="item.admin" icon="crown" class="orange--text"></air-icon>
        </v-row>
        <v-col class="pa-0 my-5">
            <vs-checkbox v-model="item.admin">
                Администратор
            </vs-checkbox>
            <v-col class="pa-0" v-if="!item.admin">
                <air-divider class="my-2"></air-divider>
                <vs-checkbox :val="el" v-model="item.rights" v-for="(el, index) in $projectRight.list"
                             :key="index">
                    {{ el.title }}
                </vs-checkbox>
            </v-col>
        </v-col>
        <v-row no-gutters align="center">
            <air-button no-gutters @click="saveProjectUser" floating>Сохранить</air-button>
            <v-spacer></v-spacer>
            <air-button danger transparent @click="deleteProjectUser">Удалить</air-button>
        </v-row>
    </air-dialog>
</template>

<script>
export default {
    name: 'ProjectUserDialog',
    props: {
        value: Boolean
    },
    data: () => ({}),
    methods: {
        deleteProjectUser() {
            this.$emit('input', false)
            this.$projectUser.destroy().then(() => {
                this.$projectUser.deleteByPk(this.item.id, this.$project.item.project_users)
                this.$snackbar.success('Пользователь удален из проекта')
            }).catch(() => {
                this.$snackbar.fail('Не удалось удалить пользователя')
            })
        },
        saveProjectUser() {
            this.$emit('input', false)
            this.$projectUser.update().then(res => {
                this.$snackbar.success('Пользователь сохранен')
                const index = this.$projectUser.findIndexByPk(this.item.id, this.$project.item.project_users)
                if (index) {
                    this.$project.item.project_users.splice(index, 1, res)
                }
            }).catch(() => {
                this.$snackbar.fail('Не сохранить пользователя')
            })
        }
    },
    computed: {
        item() {
            return this.$projectUser.item
        }
    }
}
</script>

<style scoped>

</style>