<template>
    <v-row no-gutters align="center" v-if="view">
        <div style="height: 16px; width: 16px">
            <v-img height="16" max-width="16" v-if="view.icon" :src="view.icon"></v-img>
        </div>
        <div class="px-3 primary--text">
            <a :href="password.address" v-if="view.url" target="_blank">
                {{ password.name ? password.name : view.name }}
            </a>
            <div v-else @click="copyAddress" class="pointer">
                {{ password.name ? password.name : view.name }}
            </div>
        </div>
    </v-row>
</template>

<script>
export default {
    name: "AddressView",
    props: {
        password: Object
    },
    data: () => ({
        view: null
    }),
    methods: {
        setAddressView(address) {
            this.view = this.$password.getAddressView(address)
        },
        copyAddress() {
            window.copy(this.password.address)
            this.$snackbar.success('Адрес скопирован')
        }
    },
    created() {
        this.setAddressView(this.password.address)
    },
    watch: {
        password(val) {
            this.setAddressView(val.address)
        }
    }
}
</script>

<style scoped>

</style>