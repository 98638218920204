<template>
    <v-hover v-slot="{ hover }">
        <v-btn :to="to || undefined" icon @click="$emit('click')" :color="active || hover ? 'primary' : 'secondary'">
            <v-icon size="23">
                <slot></slot>
            </v-icon>
        </v-btn>
    </v-hover>
</template>

<script>
export default {
    name: "ActionIcon",
    props: {
        active: Boolean,
        to: Object
    }
}
</script>

<style scoped>

</style>