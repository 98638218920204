<template>

    <v-row no-gutters align="center">
        <v-col cols="12" md="">
            <v-row no-gutters align="center">
                <v-row no-gutters align="center" justify="center" style="max-width: 50px">
                    <air-icon class="grey--text text--darken-2" size="40" icon="key"></air-icon>
                </v-row>
                <v-col class="mx-5">
                    <div style="font-weight: 600">Смена пароля</div>
                    <div class="caption grey--text text--darken-2">Используется для улучшения безопасности</div>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" md="auto" class="mt-3 mt-md-0">
            <air-button @click="dialog = true" floating>Сменить пароль</air-button>
            <air-dialog v-model="dialog" title="Смена пароля">
                <air-form v-model="valid">
                    <air-text-field type="password"
                                    :rules="[$rules.required]"
                                    v-model="oldPassword" placeholder="Старый пароль" icon="key"></air-text-field>
                    <air-text-field type="password"
                                    :rules="[$rules.required]"
                                    class="mt-5" v-model="newPassword" placeholder="Новый пароль"
                                    icon="key"></air-text-field>
                    <air-text-field type="password"
                                    :rules="[$rules.required, $rules.match(newPassword)]"
                                    class="mt-5" v-model="repeatPassword" placeholder="Подтвердите новый пароль"
                                    icon="redo-alt"></air-text-field>
                    <air-button :loading="loading" :disabled="!valid" @click="changePassword" block floating
                                class="mt-7">
                        Сохранить
                    </air-button>
                </air-form>
            </air-dialog>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'ChangePassword',
    data: () => ({
        dialog: false,
        oldPassword: '',
        newPassword: '',
        repeatPassword: '',
        valid: false,
        loading: false
    }),
    methods: {
        changePassword() {
            const credentials = this.$project.generateCredentials(this.oldPassword, this.$auth.user.uuid)
            const newCredentials = this.$project.generateCredentials(this.newPassword, this.$auth.user.uuid)
            this.$project.loadItem(this.$project.personalProject.id).then(() => {
                let passwords = []
                let childProjects = []
                let notes = []
                this.$project.item.password_groups.forEach(group => {
                    group.passwords.forEach(val => {
                        const decrypted = this.$crypto.decrypt(val.password, credentials.password)
                        const encrypted = this.$crypto.encrypt(decrypted, newCredentials.password)
                        passwords.push({
                            id: val.id,
                            password: encrypted
                        })
                    })
                })
                this.$project.item.child_projects.forEach(val => {
                    const decrypted = this.$crypto.decrypt(val.password, credentials.password)
                    const encrypted = this.$crypto.encrypt(decrypted, newCredentials.password)
                    childProjects.push({
                        id: val.id,
                        password: encrypted
                    })
                })
                this.$project.item.notes.forEach(val => {
                    const decrypted = this.$crypto.decrypt(val.text, credentials.password)
                    const encrypted = this.$crypto.encrypt(decrypted, newCredentials.password)
                    notes.push({
                        id: val.id,
                        text: encrypted
                    })
                })
                this.loading = true
                this.$user.sendPost('change_password', {
                    old_password: credentials.hash,
                    new_password: newCredentials.hash,
                    passwords: passwords,
                    project_relations: childProjects,
                    notes: notes
                }).then(res => {
                    this.loading = false
                    this.dialog = false
                    this.$auth.user = res
                    localStorage.setItem(newCredentials.uuid, newCredentials.password)
                    this.$snackbar.success('Пароль изменен')
                }).catch(() => {
                    this.loading = false
                    this.$snackbar.fail('Не удалось изменить пароль')
                })
            })
        }
    }
}
</script>

<style scoped>

</style>