const m = require('air-vue-model/model')()
const crypto = require('./crypto')
const project = require('./project/project')
m.url = 'passwords'
m.name = 'password'
m.default = {
    address: '',
    name: '',
    password: '',
    tags: [],
    description: '',
    password_group: null
}
m.dialog = false

m.filters = {
    search: ''
}

m.openDialog = (password = null) => {
    setTimeout(() => {
        m.item = m.copy(password || m.default)
        m.item.password = ''
        if (m.item.encrypt_description) {
            const credentials = project.getCredentials(project.item.uuid)
            m.item.description = crypto.decrypt(m.item.description, credentials.password)
        }
    }, 50)
    m.dialog = true
}

m.getAddressView = address => {
    try {
        let url = new URL(address)
        return {
            name: url.hostname,
            icon: 'https://s2.googleusercontent.com/s2/favicons?domain=' + url.hostname,
            url: true
        }
    } catch (e) {
        return {
            name: address,
            icon: null
        }
    }
}

module.exports = m
