<template>
    <v-row no-gutters align="start" class="flex-nowrap">
        <air-text-field
            :disableAutocomplete="disableAutocomplete"
            type="password"
            :visible-password="showPassword"
            @click-icon="showPassword = !showPassword"
            :rules="disableRules ? [] : [$rules.required]"
            :icon="showPassword ? 'key' : 'eye'" :value="value" @input="$emit('input', $event)"
            @change="$emit('change', $event)"
            placeholder="Пароль">
        </air-text-field>
        <air-button v-if="generate" transparent @click="generatePassword" class="mr-0 ml-2 mt-0"
                    icon="sync-alt"></air-button>
    </v-row>
</template>

<script>
export default {
    name: 'PasswordInput',
    props: {
        value: String,
        generate: Boolean,
        disableRules: Boolean,
        disableAutocomplete: Boolean
    },
    data: () => ({
        showPassword: false
    }),
    methods: {
        generatePassword() {
            const password = this.$crypto.randomString(20)
            window.copy(password)
            this.$emit('input', password)
            this.$emit('change', password)
            this.showPassword = true
        },
    }
}
</script>

<style scoped>

</style>