import './models'
import './vendor/bootstrap'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './vendor/vuetify'
import './vendor/vuesas'

window.copy = require('clipboard-copy')
Vue.component('air-button', require('./components/AirButton').default)
Vue.component('air-text-field', require('./components/AirTextField').default)
Vue.component('air-select', require('./components/template/AirSelect').default)
Vue.component('action-button', require('./components/ActionButton').default)
Vue.component('action-icon', require('./components/ActionIcon').default)
Vue.component('air-icon', require('./components/template/AirIcon').default)
Vue.component('air-form', require('./components/AirForm').default)
Vue.component('air-card', require('./components/AirCard').default)
Vue.component('air-dialog', require('./components/AirDialog').default)
Vue.component('air-title-text', require('./components/template/AirTitleText').default)
Vue.component('air-description-text', require('./components/AirDescriptionText').default)
Vue.component('air-divider', require('./components/template/AirDivider').default)

Vue.config.productionTip = false

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')
