<template>
    <v-layout column class="ma-0" v-if="$project.item.project_invites.length > 0">
        <div class="px-2 py-1 font-weight-medium">Ожидают подтверждения</div>
        <v-layout class="ma-0 pa-2" style="min-height: 45px" align-center :key="index"
                  v-for="(el, index) in $project.item.project_invites">
            <v-img :src="el.from_user.avatar" max-height="35" max-width="35"></v-img>
            <div class="body-1 px-3">{{el.from_user.username}}</div>
            <v-icon class="mr-3">mdi-chevron-right</v-icon>
            <v-img :src="el.to_user.avatar" max-height="35" max-width="35"></v-img>
            <div class="body-1 px-3">{{el.to_user.username}}</div>
            <v-spacer></v-spacer>
            <v-btn @click="deleteInvite(el, index)" text color="red" class="text-none">Удалить</v-btn>
        </v-layout>
    </v-layout>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "AwaitingInvites",
        computed: {
            ...mapState({
                project: state => state.items.project.item
            }),
        },
        methods: {
            deleteInvite(el, index) {
                this.$store.dispatch('deleteItem', {
                    url: '/api/v1/project_invites/',
                    id: el.id
                }).then(() => {
                    this.project.project_invites.splice(index, 1)
                })
            }
        }
    }
</script>

<style scoped>

</style>