<template>
    <v-divider :vertical="vertical" class="grey lighten-4"></v-divider>
</template>

<script>
export default {
    name: 'AirDivider',
    props: {
        vertical: Boolean
    },
    data: () => ({}),
    methods: {}
}
</script>

<style scoped>

</style>