import sha256 from 'crypto-js/sha256';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import aes from 'crypto-js/aes'
import utf8 from 'crypto-js/enc-utf8'

export default {
    data: () => ({}),
    methods: {
        encryptText(text, key) {
            return aes.encrypt(text, key).toString()
        },
        decryptText(text, key) {
            return aes.decrypt(text, key).toString(utf8)
        },
        hashText(text) {
            return sha256(text).toString()
        },
        generateUuid() {
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16))
        },
        generateProjectCredentials(password, uuid = null) {
            if (!uuid)
                uuid = this.generateUuid()
            let cryptoPassword = hmacSHA256(uuid, password).toString()
            return {
                uuid: uuid,
                cryptoPassword: cryptoPassword,
                cryptoHash: sha256(cryptoPassword).toString()
            }
        },
        getLocalProjectCredentials(uuid) {
            let cryptoPassword = localStorage.getItem(uuid)
            let cryptoHash = cryptoPassword ? sha256(cryptoPassword).toString() : null
            return {
                uuid: uuid,
                cryptoPassword: cryptoPassword,
                cryptoHash: cryptoHash
            }
        },
        generateRandomString(len) {
            let password = "";
            let symbols = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!№;%:?*()_+-=&^{}[]|/";
            for (let i = 0; i < len; i++) {
                password += symbols.charAt(Math.floor(Math.random() * symbols.length));
            }
            return password;
        }
    }
}