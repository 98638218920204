<template>
    <v-col class="pa-0" v-if="$project.item">
        <v-row no-gutters align="center" class="mb-2 mt-5">
            <air-button class="ml-0 mr-3" @click="$project.changeLock" floating
                        :icon="$project.item.decrypted ? 'eye' : 'eye-slash'"></air-button>
            <air-title-text class="mb-0 mt-0">{{ $project.item.name }}</air-title-text>
        </v-row>
        <project-actions></project-actions>
        <air-divider class="mb-4 mt-2"></air-divider>
        <router-view></router-view>
        <project-password-dialog></project-password-dialog>
    </v-col>
</template>

<script>
import ProjectActions from "@/views/projects/item/ProjectActions";
import ProjectPasswordDialog from "@/views/projects/item/ProjectUnlockDialog";

export default {
    name: "Project",
    data: () => ({}),
    components: {
        ProjectActions,
        ProjectPasswordDialog
    },
    methods: {},
    created() {
        this.$password.filters.search = ''
        this.$note.filters.search = ''
        this.$projectRelation.filters.search = ''
    }

}
</script>

<style scoped>

</style>