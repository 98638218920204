<template>
    <v-col class="pa-0">
        <air-list-template v-model="$note.filters.search"
                           v-if="$project.hasRight('VIEW_NOTES')"
                           :items="$project.noteSearchResult">
            <template v-slot:actions v-if="$project.hasRight('EDIT_NOTES')">
                <v-spacer></v-spacer>
                <air-button floating :to="{name: 'projectNote', params: {noteId: 'new'}}">Создать</air-button>
            </template>
            <template v-slot:item="{item}">
                <router-link :to="{name: 'projectNote', params: {noteId: item.id}}">
                    {{ item.name }}
                </router-link>
            </template>
        </air-list-template>
        <v-row v-else no-gutters align="center" justify="center" class="mt-10">
            <v-col cols="10" sm="6" md="5" lg="5">
                <v-row no-gutters align="center" justify="center" class="flex-column">
                    <v-img src="/static/images/canvas.svg"></v-img>
                    <div class="mt-5 body-2 grey--text text--darken-2">У вас нет доступа к заметкам</div>
                </v-row>
            </v-col>
        </v-row>
    </v-col>

    <!--    <v-col class="pa-0">-->
    <!--        <notes-actions class="mb-4"></notes-actions>-->
    <!--        <v-col class="pa-0 body-2" v-for="(el, index) in $project.noteSearchResult" :key="index">-->
    <!--            <air-divider></air-divider>-->
    <!--            <v-row no-gutters align="center" style="min-height: 40px">-->
    <!--                <router-link :to="{name: 'projectNote', params: {noteId: el.id}}">-->
    <!--                    <div class="px-3 font-weight-medium">-->
    <!--                        {{ el.name }}-->
    <!--                    </div>-->
    <!--                </router-link>-->
    <!--            </v-row>-->
    <!--        </v-col>-->

    <!--        <v-col class="py-4" v-if="$project.item.notes.length === 0">-->
    <!--            Не создано ни одной заметки-->
    <!--        </v-col>-->
    <!--    </v-col>-->
</template>

<script>
import {mapState} from "vuex";
import NotesActions from "@/views/projects/item/notes/NotesActions";
import AirListTemplate from "@/components/template/AirListTemplate";

export default {
    name: "NoteList",
    components: {
        NotesActions,
        AirListTemplate
    },
    data: () => ({}),
    methods: {},
}
</script>

<style scoped>

</style>