<template>
    <air-dialog title="Работа с паролем" v-model="$password.dialog" v-if="$password.item">
        <air-form v-model="valid">
            <air-text-field
                disable-autocomplete
                @change="setName"
                :rules="[$rules.required]"
                icon="at" v-model="$password.item.address"
                placeholder="Адрес"></air-text-field>
            <air-text-field
                disable-autocomplete
                :rules="[$rules.required]"
                class="mt-5" icon="signature" v-model="$password.item.name"
                placeholder="Наименование"></air-text-field>
            <air-text-field disable-autocomplete class="mt-5" icon="sign-in-alt" v-model="$password.item.login"
                            placeholder="Логин"></air-text-field>
            <password-input
                disable-autocomplete
                :disable-rules="!!$password.item.id"
                class="mt-5" v-model="$password.item.password" generate></password-input>
            <air-text-field class="mt-5" v-model="$password.item.description"
                            placeholder="Описание"></air-text-field>
            <air-select class="mt-5" v-model="$password.item.password_group"
                        v-if="$project.item.password_groups.filter(val => val.id).length"
                        :items="$project.item.password_groups.filter(val => val.id)"></air-select>
            <vs-checkbox v-if="$password.item.description" class="mt-5" v-model="$password.item.encrypt_description">
                Зашифированное описание
            </vs-checkbox>
            <air-button :disabled="!valid" floating @click="saveItem" block class="mt-7">Сохранить</air-button>
            <air-button
                v-if="$password.item.id"
                transparent danger @click="deletePassword" block class="mt-5">Удалить
            </air-button>
        </air-form>
    </air-dialog>
</template>

<script>
import PasswordInput from "@/components/PasswordInput";

export default {
    name: 'PasswordDialog',
    components: {
        PasswordInput
    },
    data: () => ({
        valid: false
    }),
    methods: {
        setName(value) {
            if (this.$password.item.name)
                return
            try {
                let name = new URL(value).hostname
                const index = name.lastIndexOf('.')
                if (index > -1)
                    name = name.substr(0, index)
                name = name.replace('www.', '')
                this.$password.item.name = name
            } catch (e) {
                this.$password.item.name = value
            }
        },
        async saveItem() {
            let value = {...this.$password.item}
            const credentials = this.$project.getCredentials(this.$project.item.uuid)
            if (!value.password)
                delete value.password
            else
                value.password = this.$crypto.encrypt(value.password, credentials.password)
            if (!value.description)
                value.encrypt_description = false
            else if (value.encrypt_description)
                value.description = this.$crypto.encrypt(value.description, credentials.password)
            if (!value.project) {
                value.project = this.$project.item.id
            }
            value.hash = credentials.hash
            this.$password.dialog = false
            const {promise, created} = this.$password.updateOrCreate(value)
            promise.then(res => {
                this.$snackbar.success('Пароль сохранен')
                this.$project.loadItem(this.$project.item.id).then(() => {
                    const group = this.$project.item.password_groups.find(val => val.id === res.password_group)
                    group.hidden = false
                })
            }).catch(() => {
                this.$snackbar.fail('Не удалось сохранить пароль')
            })
        },
        deletePassword() {
            const password_group = this.$password.item.password_group
            this.$password.dialog = false
            const credentials = this.$project.getCredentials(this.$project.item.uuid)
            this.$password.destroy(this.$password.item.id, {hash: credentials.hash}).then(() => {
                this.$snackbar.success('Пароль удален')
                this.$project.loadItem(this.$project.item.id).then(() => {
                    const group = this.$project.item.password_groups.find(val => val.id === password_group)
                    group.hidden = false
                })
            }).catch(() => {
                this.$snackbar.fail('Не удалось удалить пароль')
            })
        },
    },
    computed: {
        item() {
            return this.$password.item
        }
    }
}
</script>

<style scoped>

</style>