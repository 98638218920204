<template>
    <v-row no-gutters align="center" style="max-width: 100%; overflow-x: auto" class="flex-nowrap">
        <div v-for="(el, index) in items" :key="index">
            <v-row no-gutters align="center" style="min-height: 50px;"
                   class="flex-nowrap py-2">
                <air-divider vertical v-if="index" class="mx-4"></air-divider>
                <action-button
                    :count="el.lengthKey ? $project.item[el.lengthKey].length : null"
                    :active="$route.name === el.to"
                    :to="{name: el.to}" :icon="el.icon">{{ el.title }}
                </action-button>
            </v-row>
        </div>
    </v-row>
</template>

<script>
export default {
    name: "ProfileActions",
    data: () => ({
        items: [
            {title: 'Профиль', icon: 'key', to: 'userInfo'},
            {title: 'Приглашения', icon: 'project-diagram', to: 'userInvites'},
            {title: 'Безопасность', icon: 'user-shield', to: 'userSecurity'},
        ]
    })
}
</script>

<style scoped>

</style>