<template>
    <air-dialog blur not-close :title="$project.item.name"
                description="Разблокировка проекта"
                v-model="$project.passwordDialog.dialog">
        <air-form v-model="valid">
            <password-input v-model="$project.passwordDialog.password"></password-input>
            <air-button :disabled="!valid" floating class="mt-7" @click="resetProjectPassword" block>Открыть проект
            </air-button>
        </air-form>
        <air-button class="mt-4" transparent @click="$router.push({name: 'projectList'})" block>Вернуться к
            списку
        </air-button>
        <exit-from-project class="mt-2"></exit-from-project>
    </air-dialog>
</template>

<script>
import ExitFromProject from "@/views/projects/ExitFromProject";
import PasswordInput from "@/components/PasswordInput";

export default {
    name: "ProjectUnlockDialog",
    components: {
        ExitFromProject,
        PasswordInput
    },
    data: () => ({
        showPassword: false,
        valid: false
    }),
    methods: {
        resetProjectPassword() {
            this.$project.unlock(this.$project.passwordDialog.password)
        },
    }
}
</script>

<style scoped>

</style>