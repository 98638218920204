<template>
    <v-app>
        <v-main class="background">
            <router-view></router-view>
        </v-main>
        <snackbar></snackbar>
    </v-app>
</template>

<script>

import Snackbar from "@/components/Snackbar";

export default {
    name: 'App',
    components: {
        Snackbar
    },

    data: () => ({}),
    created() {
        this.$projectRight.loadList()
    },
    methods: {}
};
</script>

<style>
a {
    text-decoration: none;
}

.v-text-field--outlined fieldset {
    border: 1px solid !important;
}

.v-text-field--outlined.nav-bar-input input {
    color: rgba(236, 236, 236, 0.87) !important;
}

.pointer {
    cursor: pointer;
}

p {
    margin: 0 !important;
}

</style>
