const m = require('air-vue-model/model')()
m.url = 'notes'
m.name = 'note'
m.default = {
    name: '',
    text: '',
    decrypted: ''
}
m.dialog = false

module.exports = m
