<template>
    <v-row no-gutters align="center" justify="center" class="gradient-auth fill-height">
        <v-container>
            <v-row no-gutters align="center">
                <v-col cols="12" md="6" offset-md="3" class="vs-card pa-5">
                    <v-row no-gutters align="center" justify="center">
                        <div class="pb-7 pt-3 subtitle-1">{{ title }}</div>
                    </v-row>
                    <slot></slot>
                </v-col>
            </v-row>
        </v-container>
    </v-row>
</template>

<script>
export default {
    name: 'AuthView',
    props: {
        title: String
    },
    data: () => ({}),
    methods: {}
}
</script>

<style scoped>
.gradient-auth {
    background: rgb(91, 60, 196);
    background: linear-gradient(45deg, rgba(91, 60, 196, 1) 22%, rgba(109, 35, 150, 1) 88%);
}

.vs-card {
    max-width: none !important;
    cursor: auto;
    width: 100% !important;
}
</style>