<template>
    <v-col class="pa-0">
        <v-row v-if="!hideActions" no-gutters align="center" class="mb-1 flex-nowrap" style="min-height: 45px">
            <air-text-field
                style="max-width: 200px"
                placeholder="Поиск"
                icon="search"
                :value="value" @input="$emit('input', $event)"></air-text-field>
            <slot name="actions"></slot>
        </v-row>
        <v-col class="pa-0" v-if="!value">
            <v-col class="pa-0" v-for="(item, index) in items" :key="index">
                <air-divider v-if="index"></air-divider>
                <v-col class="pa-0">
                    <air-list-element-template>
                        <slot name="item" v-bind:item="item" v-bind:index="index"></slot>
                    </air-list-element-template>
                    <v-col class="pa-0" v-if="childField && !item.hidden">
                        <v-col class="pa-0" v-for="(child, childIndex) in item[childField]"
                               :key="childIndex">
                            <air-divider></air-divider>
                            <air-list-element-template>
                                <slot name="child" v-bind:child="child" v-bind:index="index"></slot>
                            </air-list-element-template>
                        </v-col>
                    </v-col>
                </v-col>
            </v-col>
        </v-col>
        <v-col class="pa-0" v-else>
            <v-col class="pa-0" v-for="(item, index) in searchList" :key="index">
                <air-divider v-if="index"></air-divider>
                <air-list-element-template>
                    <slot name="item" v-if="!childField" v-bind:item="item"></slot>
                    <slot name="child" v-bind:child="item" v-else></slot>
                </air-list-element-template>
            </v-col>
        </v-col>
    </v-col>
</template>

<script>
import AirListElementTemplate from "@/components/template/AirListElementTemplate"
import search from 'air-vue-model/search'

export default {
    name: 'AirListTemplate',
    components: {
        AirListElementTemplate
    },
    props: {
        items: Array,
        component: Object,
        hideActions: Boolean,
        value: String,
        childField: String,
        searchFunction: {
            type: Function,
            default: (val) => val.name
        }
    },
    data: () => ({
        show: true
    }),
    methods: {},
    created() {

    },
    computed: {
        searchList() {
            let values = []
            if (this.childField)
                this.items.forEach(val => {
                    val[this.childField].forEach(child => {
                        values.push(child)
                    })
                })
            else
                values = this.items
            return search(this.value, values, this.searchFunction)
        },
    }
}
</script>

<style scoped>

</style>