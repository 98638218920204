import { render, staticRenderFns } from "./AirListElementTemplate.vue?vue&type=template&id=465a540d&scoped=true"
import script from "./AirListElementTemplate.vue?vue&type=script&lang=js"
export * from "./AirListElementTemplate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "465a540d",
  null
  
)

export default component.exports