<template>
    <v-col class="pa-0">
        <v-row no-gutters align="center" v-if="$vuetify.breakpoint.mdAndUp">
            <v-col cols="6" md="4">
                <address-view :password="item"></address-view>
            </v-col>
            <v-col cols="6" md="4">
                <div style="cursor: pointer" @click="copyLogin">{{ item.login }}</div>
            </v-col>
            <v-col cols="12" md="4">
                <v-row no-gutters align="center" justify="space-between" class="flex-nowrap">
                    <password-view :password="item"></password-view>
                    <password-actions :password="item"></password-actions>
                </v-row>
            </v-col>
        </v-row>
        <v-col v-else class="pa-0">
            <v-row no-gutters align="center" justify="space-between">
                <address-view :password="item"></address-view>
                <password-actions :password="item"></password-actions>
            </v-row>
            <v-col no-gutters class="pa-0 pl-7">
                <div style="cursor: pointer" @click="copyLogin">{{ item.login }}</div>
                <password-view class="mt-2" :password="item"></password-view>
            </v-col>
        </v-col>
        <div v-if="item.description" class="pl-7 caption grey--text">
            <div v-if="!item.encrypt_description">{{ item.description }}</div>
            <div v-else>{{ item.decrypted_description || 'Описание зашифровано' }}</div>
        </div>
    </v-col>
</template>

<script>
import PasswordActions from "@/views/projects/item/passwords/listElement/PasswordActions";
import PasswordView from "@/views/projects/item/passwords/listElement/PasswordView";
import AddressView from "@/views/projects/item/passwords/listElement/AddressView";
import AirListElementTemplate from "@/components/template/AirListElementTemplate";

export default {
    name: "Password",
    props: {
        item: Object,
    },
    components: {
        PasswordActions,
        PasswordView,
        AddressView,
        AirListElementTemplate
    },
    data: () => ({}),
    methods: {
        copyLogin() {
            window.copy(this.item.login)
            this.$snackbar.success('Логин скопирован')
        }
    },
    computed: {},
    created() {

    }
}
</script>

<style scoped>

</style>