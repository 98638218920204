const m = require('air-vue-model/model')()
const project = require('./project/project')
m.url = 'password_groups'
m.name = 'password_group'
m.default = {
    name: '',
    icon: 'layer-group',
    project: null
}
m.dialog = false

m.openDialog = (passwordGroup = null) => {
    m.item = m.copy(passwordGroup || m.default)
    m.item.project = project.item.id
    setTimeout(() => {
        m.dialog = true
    }, 50)
}

module.exports = m
