const note = require('../note')

module.exports = [
    {
        path: '',
        name: 'list',
        component: require('@/views/projects/list/Projects')
    },
    {
        name: 'item',
        single: true,
        component: require('@/views/projects/item/Project'),
        children: [
            {
                name: 'passwords',
                path: 'passwords',
                component: require('@/views/projects/item/passwords/Passwords'),
            },
            {
                name: 'childProjects',
                path: 'child_projects',
                component: require('@/views/projects/item/childProjects/ChildProjects'),
            },
            {
                name: '_notes',
                component: require('@/views/projects/item/notes/Notes'),
                path: '',
                children: [
                    {
                        name: 'notes',
                        component: require('@/views/projects/item/notes/NoteList'),
                        model: note
                    },
                    {
                        name: 'note',
                        single: true,
                        component: require('@/views/projects/item/notes/Note'),
                        model: note
                    },
                ]
            },
            {
                path: 'users',
                name: 'users',
                component: require('@/views/projects/item/users/ProjectUsers')
            },
            {
                path: 'settings',
                name: 'settings',
                component: require('@/views/projects/item/settings/Settings')
            }
        ]
    }
]