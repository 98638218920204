<template>
    <router-link :to="to" :is="!to ? 'div' : 'router-link'" style="margin: 5px">
        <vs-button
            :success="success"
            :active="active"
            :disabled="disabled"
            :transparent="transparent"
            :floating="floating"
            :flat="flat"
            :loading="loading"
            :border="border"
            :danger="danger"
            :gradient="gradient"
            style="min-height: 38px; min-width: 38px"
            :style="`width: ${currentWidth}; height: ${currentHeight}`"
            :color="color"
            class="px-2 ma-0" icon @click="$emit('click')" :block="block">
            <air-icon :size="iconSize" :class="{'mr-2': $slots.default}" :icon="icon" v-if="icon && left"></air-icon>
            <slot v-if="$slots.default"></slot>
            <air-icon :size="iconSize" :class="{'ml-2': $slots.default}" :icon="icon" v-if="icon && !left"></air-icon>
        </vs-button>
    </router-link>
</template>

<script>

import AirIcon from "@/components/template/AirIcon";

export default {
    name: 'AirButton',
    props: {
        left: Boolean,
        floating: Boolean,
        active: Boolean,
        transparent: Boolean,
        flat: Boolean,
        icon: String,
        block: Boolean,
        gradient: Boolean,
        color: String,
        danger: Boolean,
        to: Object,
        border: Boolean,
        loading: Boolean,
        success: Boolean,
        noGutters: Boolean,
        disabled: Boolean,
        width: String,
        height: String,
        iconSize: {
            type: [String, Number],
            default: 13
        }
    },
    components: {
        AirIcon
    },
    data: () => ({}),
    methods: {},
    computed: {
        currentWidth() {
            if (this.width)
                return this.width
            if (this.icon && !this.$slots.default)
                return '38px'
        },
        currentHeight() {
            if (this.height)
                return this.height
            if (this.icon && !this.$slots.default)
                return '38px'
        }
    }
}
</script>

<style scoped>

</style>