<template>
    <air-dialog title="Работа с группой проектов" v-model="$projectGroup.dialog" v-if="item">
        <v-layout column class="ma-0">
            <air-form v-model="valid">
                <air-text-field :rules="[$rules.required]" v-model="item.name" icon="signature"
                                placeholder="Наименование"></air-text-field>
                <air-text-field :rules="[$rules.required]" v-model="item.sorting" class="mt-5" icon="sort"
                                placeholder="Сортировка"></air-text-field>
                <icons class="mt-3" v-model="item.icon"></icons>
                <air-button :disabled="!valid" floating class="mt-5" @click="save" block>Сохранить</air-button>
                <air-button v-if="item.id" danger transparent class="mt-3" @click="deleteItem" block>Удалить
                </air-button>
            </air-form>
        </v-layout>
    </air-dialog>
</template>

<script>
import Icons from "@/views/projects/item/passwords/passwordGroup/Icons";

export default {
    name: 'ProjectGroupDialog',
    components: {
        Icons
    },
    data: () => ({
        valid: false
    }),
    methods: {
        deleteItem() {
            this.$projectGroup.destroy().then(() => {
                this.$snackbar.success('Группа удалена')
                this.$projectGroup.dialog = false
                this.$projectGroup.loadList()
            })
        },
        save() {
            const {promise, created} = this.$projectGroup.updateOrCreate()
            promise.then(res => {
                this.$snackbar.success('Группа сохранена')
                this.$projectGroup.loadList()
                this.$projectGroup.dialog = false
            })
        },
    },
    computed: {
        item() {
            return this.$projectGroup.item
        }
    }
}
</script>

<style scoped>

</style>