const aes = require('crypto-js/aes')
const utf8 = require('crypto-js/enc-utf8')
const sha256 = require('crypto-js/sha256')
const hmacSHA256 = require('crypto-js/hmac-sha256')

const crypto = {
    encrypt(text, key) {
        return aes.encrypt(text, key).toString()
    },
    decrypt(text, key) {
        return aes.decrypt(text, key).toString(utf8)
    },
    hash(text) {
        return text ? sha256(text).toString() : null
    },
    hmac(key, value) {
        return hmacSHA256(key, value).toString()
    },
    uuid4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8)
            return v.toString(16)
        })
    },
    randomString(len) {
        let password = "";
        let symbols = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!№;%:?*()_+-=&^{}[]|/";
        for (let i = 0; i < len; i++) {
            password += symbols.charAt(Math.floor(Math.random() * symbols.length));
        }
        return password;
    }
}

module.exports = crypto