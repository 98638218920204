<template>
    <span>
        <air-button class="mx-0" @click="openDialog" floating>Создать</air-button>
        <air-dialog title="Создание проекта" v-model="dialog" v-if="$project.item">
            <air-form v-model="valid">
                <air-text-field
                    disable-autocomplete
                    :rules="[$rules.required]"
                    icon="signature" v-model="$project.item.name"
                    placeholder="Наименование проекта">
                </air-text-field>
                <password-input disable-autocomplete generate class="mt-5" v-model="password"></password-input>
                <air-select class="mt-5" v-if="$projectGroup.list.length"
                            v-model="$project.item.project_group"
                            :items="$projectGroup.list"></air-select>
                <air-button :disabled="!valid" floating @click="saveItem" block
                            class="mt-6">Сохранить</air-button>
            </air-form>
        </air-dialog>
    </span>
</template>

<script>
import PasswordInput from "@/components/PasswordInput";

export default {
    name: "ProjectCreateDialog",
    components: {
        PasswordInput
    },
    data: () => ({
        password: '',
        showPassword: false,
        dialog: false,
        valid: false
    }),
    methods: {
        saveItem() {
            let value = {...this.$project.item}
            let credentials = this.$project.generateCredentials(this.password)
            value.uuid = credentials.uuid
            value.hash = credentials.hash
            value.password = credentials.password
            this.$project.create(value).then(res => {
                this.$snackbar.success('Проект сохранен')
                localStorage.setItem(credentials.uuid, credentials.password)
                this.$projectGroup.loadList()
                this.dialog = false
                const personalProject = this.$project.personalProject
                const personalCredentials = this.$project.getCredentials(personalProject.uuid)
                const encryptedPassword = this.$crypto.encrypt(this.password, personalCredentials.password)
                this.$projectRelation.setRelation({
                    child_project: res.id,
                    parent_project: personalProject.id,
                    password: encryptedPassword,
                    child_hash: credentials.hash,
                    parent_hash: personalCredentials.hash
                })
            })
        },
        openDialog() {
            this.password = ''
            this.$project.setItemFromDefault()
            setTimeout(() => {
                this.dialog = true
            }, 50)
        }
    }
}
</script>

<style scoped>

</style>