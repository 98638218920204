<template>
    <vs-select
        block
        class="body-2"
        placeholder="Группа"
        :value="value || ''"
        @input="emitValue"
    >
        <vs-option :key="index" v-for="(el, index) in items" v-if="el[itemValue]" :label="el[itemText]"
                   :value="el[itemValue]">
            {{ el[itemText] }}
        </vs-option>
    </vs-select>
</template>

<script>
export default {
    name: 'AirSelect',
    props: {
        items: Array,
        value: [Number, String, Object],
        itemText: {
            type: String,
            default: 'name'
        },
        itemValue: {
            type: String,
            default: 'id'
        }
    },
    data: () => ({}),
    methods: {
        emitValue(value) {
            this.$emit('input', value)
        }
    },
    computed: {
        currentValue() {
            if (!this.value)
                return ''
            return this.value.toString()
        }
    },
    created() {

    }
}
</script>

<style scoped>

</style>