import Vue from 'vue'
import VueRouter from 'vue-router'
import Register from "./views/auth/Register";
import Login from "./views/auth/Login";
import Home from "./views/Home";


const router = require('air-vue-model/router')

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            auth: true,
        },
        children: [
            ...Vue.prototype.$project.getRoutes(),
            ...Vue.prototype.$user.getRoutes()
        ]
    },
    {
        path: '/register',
        name: 'register',
        component: Register
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
]

export default router(Vue, VueRouter, routes)
