<template>
    <v-layout class="ma-0" column v-if="item">
        <v-layout class="ma-0 pb-3" align-center>
            <air-button @click="$router.back()" icon="caret-left" transparent class="title ml-0 mr-2"></air-button>
            <div>Работа с заметкой</div>
            <v-spacer></v-spacer>
            <v-row no-gutters align="center" justify="end" v-if="$project.hasRight('EDIT_NOTES')">
                <air-button @click="saveNote" :disabled="!item.name" floating v-if="edited">Сохранить</air-button>
                <air-button @click="edited = true" floating v-if="item.id && !edited">Редактировать</air-button>
                <air-button @click="deleteNote" danger floating v-if="item.id && edited">Удалить</air-button>
            </v-row>
        </v-layout>
        <v-layout column class="ma-0" v-if="edited">
            <air-text-field icon="signature" class="mb-5" v-model="item.name"
                            placeholder="Наименование"></air-text-field>
            <vue-editor v-model="item.decrypted"/>
            <v-layout class="ma-0 py-3" justify-end>
                <v-btn @click="deleteNote" :loading="deleteLoading"
                       v-if="item.id && $project.item.enable"
                       color="error" text
                       class="text-none">
                    Удалить
                </v-btn>
            </v-layout>
        </v-layout>
        <v-col v-else class="pa-0">
            <air-title-text class="mt-0">{{ item.name }}</air-title-text>
            <div v-html="item.decrypted" v-if="item.decrypted"></div>
            <v-layout v-else class="ma-0 primary--text" align-center>
                <v-icon color="primary" class="mr-2">mdi-lock</v-icon>
                <div style="cursor: pointer">Текст заметки
                    зашифрован. Расшифруйте проект
                </div>
            </v-layout>
        </v-col>
    </v-layout>
</template>

<script>
import {VueEditor, Quill} from "vue2-editor";
import {mapState} from "vuex";
import crypto from '../../../../mixins/crypto'

export default {
    name: "Note",
    components: {
        VueEditor,
    },
    mixins: [
        crypto
    ],
    data: () => ({
        saveLoading: false,
        deleteLoading: false,
        edited: false,
    }),
    methods: {
        getBack() {
            if (this.edited && this.item.id)
                this.edited = false
            else
                this.$router.back()
        },
        async saveNote() {
            this.saveLoading = true
            let value = {...this.item}
            let credentials = this.$project.getCredentials(this.$project.item.uuid)
            if (!credentials.password) {
                this.$snackbar.fail('Необходимо расшифровать проект')
                return
            }
            value.text = this.$crypto.encrypt(value.decrypted, credentials.password)
            value.project = this.$project.item.id
            value.hash = credentials.hash
            const {promise, created} = this.$note.updateOrCreate(value, {setToModel: false})
            promise.then(async res => {
                this.$snackbar.success('Заметка сохранена')
                if (created) {
                    await this.$router.replace({name: 'projectNote', params: {'noteId': res.id}})
                    this.$project.item.notes.push(res)
                } else {
                    const note = this.$note.findByPk(res.id, this.$project.item.notes)
                    if (note) {
                        note.name = res.name
                    }
                }
                res.decrypted = value.decrypted
                this.$note.item = res
                this.edited = false
                this.saveLoading = false
            })
        },
        deleteNote() {
            let credentials = this.$project.getCredentials(this.$project.item.uuid)
            const id = this.$note.item.id
            this.deleteLoading = true
            this.$note.destroy(id, {hash: credentials.hash}).then(() => {
                this.$snackbar.success('Заметка удалена')
                this.$project.deleteByPk(id, this.$project.item.notes)
                this.$router.replace({
                    name: 'projectNotes',
                })
                this.deleteLoading = false
            }).catch(() => {
                this.$snackbar.fail('Не удалось удалить заметку')
            })
        },
        decryptNote() {
            if (this.$note.item.id) {
                let credentials = this.$project.getCredentials(this.$project.item.uuid)
                if (credentials.password) {
                    this.$note.item.decrypted = this.$crypto.decrypt(this.$note.item.text, credentials.password)
                }
            } else {
                this.edited = true
            }
        }
    },
    computed: {
        ...mapState({
            project: state => state.items.project.item,
        }),
        item() {
            return this.$note.item
        }
    },
    async created() {
        this.decryptNote()
    },
    watch: {
        // 'project.enable'(val) {
        //     if (val) {
        //         this.decryptNote()
        //     } else {
        //         if (this.edited)
        //             this.$router.push( {
        //                 name: 'noteList',
        //             })
        //         this.edited = false
        //     }
        // },
    }
}
</script>

<style>
.ql-toolbar {
    background: #F5F7F7;
    border: none !important;
    border-radius: 10px 10px 0 0;
}

.ql-container {
    background: #F5F7F7;
    border: none !important;
    border-top: 1px solid #e9eaea !important;
    border-radius: 0 0 10px 10px;
}

</style>