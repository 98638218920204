<template>
    <v-row no-gutters align="center">
        <div>
            <air-text-field icon="search" placeholder="Поиск" v-model="$project.noteSearch"></air-text-field>
        </div>
        <v-spacer></v-spacer>
        <air-button @click="createNote" floating>Создать заметку</air-button>
    </v-row>
</template>

<script>
export default {
    name: "NotesActions",
    data: () => ({}),
    methods: {
        createNote() {
            this.$router.push({name: 'projectNote', params: {noteId: 'new'}})
        }
    },
    watch: {}
}
</script>

<style scoped>

</style>