<template>
    <v-col class="ma-0">
        <authenticator></authenticator>
        <air-divider class="my-5"></air-divider>
        <change-password></change-password>
        <air-divider class="my-5"></air-divider>
        <sessions></sessions>
    </v-col>
</template>

<script>
import Authenticator from "@/views/user/Authenticator";
import ChangePassword from "@/views/user/ChangePassword";
import Sessions from "@/views/user/Sessions";

export default {
    name: 'Security',
    components: {
        Authenticator,
        ChangePassword,
        Sessions
    },
    data: () => ({}),
    methods: {}
}
</script>

<style scoped>

</style>