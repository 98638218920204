<template>
    <div>
        <v-row class="ma-0" align="center">
            <v-menu offset-y nudge-bottom="12" content-class="rounded-lg">
                <template v-slot:activator="{ on }">
                    <v-btn large text class="pl-2 pr-3" @click="dialog = true" v-on="on">
                        <v-badge
                            color="red"
                            icon="mdi-bell-ring"
                            :value="$auth.user.came_project_invites.length > 0"
                            overlap
                            bottom
                            left
                        >
                            <template v-slot:badge>
                                <air-icon icon="bell"></air-icon>
                            </template>
                            <v-img :src="$auth.user.profile.avatar" max-height="30"
                                   max-width="30"></v-img>
                        </v-badge>
                        <air-icon icon="chevron-down" class="ml-3 white--text"></air-icon>
                    </v-btn>
                </template>
                <user-menu-list></user-menu-list>
            </v-menu>
        </v-row>
    </div>
</template>

<script>
import UserMenuList from "@/views/appBar/UserMenuList";

export default {
    name: "User",
    components: {
        UserMenuList
    },
    data: () => ({}),

    methods: {}
}
</script>

<style scoped>

</style>