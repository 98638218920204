const m = require('air-vue-model/model')()
const search = require('air-vue-model/search')
const auth = require('../auth')
const crypto = require('../crypto')
const projectGroup = require('../projectGroup')
const projectRelation = require('../projectRelation')
const snackbar = require('../snackbar')

m.url = 'projects'
m.name = 'project'
m.default = {
    name: '',
    hash: null,
    uuid: null,
    image: null,
    personal: false
}

m.filters = {
    search: ''
}

m.setPagination({
    page_size: 'all'
})
m.passwordDialog = {
    dialog: false,
    resetError: false,
    password: ''
}

m.loadItem = (pk, settings = {}) => require('./loadItem')(m, pk, settings)

m.openPasswordDialog = () => {
    m.passwordDialog.password = null
    m.passwordDialog.resetError = false
    m.passwordDialog.dialog = true
}

m.changeLock = () => {
    m.item.decrypted ? m.lock() : m.unlock()
}

m.lock = () => {
    m.item.password_groups.forEach(val => {
        val.passwords.forEach(pass => {
            pass.decrypted = ''
        })
    })
    m.item.notes.forEach(val => {
        val.decrypted = ''
    })
    m.item.child_projects.forEach(val => {
        val.decrypted = ''
    })
    localStorage.removeItem(m.item.uuid)
    m.item.decrypted = false
    m.openPasswordDialog()
    snackbar.success('Проект зашифрован')
}

m.getCredentials = (uuid = null) => require('./credentials').getCredentials(uuid)

m.generateCredentials = (password, uuid) => require('./credentials').generateCredentials(password, uuid)

m.getProjectRelations = () => m.sendGetSingle('get_project_relations', m.item.id)

const _unlock = (password, project) => {
    project = project || m.item
    const credentials = m.generateCredentials(password, project.uuid)
    if (credentials.hash === project.hash) {
        project.decrypted = true
        localStorage.setItem(credentials.uuid, credentials.password)
        return credentials
    }
}

m.unlock = async (password = null, project = null) => {
    project = project || m.item

    if (password) {
        const credentials = _unlock(password, project)
        if (!credentials)
            snackbar.fail('Неверный пароль проекта')
        else {
            m.passwordDialog.dialog = false
            snackbar.success('Проект расшифрован')
            const personalProject = m.personalProject
            if (personalProject.id === project.id)
                return
            const personalCredentials = m.getCredentials(m.personalProject.uuid)
            projectRelation.setRelation({
                child_project: project.id,
                parent_project: personalProject.id,
                child_hash: credentials.hash,
                parent_hash: personalCredentials.hash,
                password: crypto.encrypt(password, personalCredentials.password)
            })
        }
        return
    }
    let credentials = m.getCredentials(project.uuid)
    project.decrypted = project.hash === credentials.hash
    if (!project.decrypted && !project.personal) {
        const projectRelations = await m.getProjectRelations()
        for (const el of projectRelations) {
            const currentCredentials = m.getCredentials(el.parent_uuid)
            if (!currentCredentials.password)
                continue
            const currentPassword = crypto.decrypt(el.password, currentCredentials.password)
            _unlock(currentPassword, project)
        }
    }
    if (!project.decrypted)
        m.openPasswordDialog()
}

m.sendInvite = data => {
    const credentials = m.getCredentials(m.item.uuid)
    data.hash = credentials.hash
    return m.sendPostSingle('send_invite', m.item.id, data)
}

m.findProject = projectId => {
    for (const el of projectGroup.list) {
        for (const project of el.projects) {
            if (project.id === projectId)
                return project
        }
    }
    return null
}

m.hasRight = right => {
    const projectUser = m.currentProjectUser
    return projectUser && (projectUser.admin || projectUser.rights.find(val => val.name === right))
}

Object.defineProperties(m, {
    searchResult: {
        get: () => search(m.search, m.list)
    },
    passwordSearchResult: {
        get: () => search(m.passwordSearch, m.item.passwords)
    },
    userSearchResult: {
        get: () => search(m.userSearch, m.item.users, (val) => val.user.username)
    },
    noteSearchResult: {
        get: () => search(m.userSearch, m.item.notes)
    },
    personalProject: {
        get: () => {
            for (const group of projectGroup.list) {
                for (const project of group.projects) {
                    if (project.personal)
                        return project
                }
            }
        }
    },
    currentProjectUser: {
        get: () => {
            if (!m.item)
                return null
            return m.item.project_users.find(val => val.user.id === auth.user.id)
        }
    },
    currentProjectUserAdmin: {
        get: () => {
            const projectUser = m.currentProjectUser
            return projectUser && projectUser.admin
        }
    }
})

m.routes = require('../routes/projectRoutes')

module.exports = m
