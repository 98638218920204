<template>
    <v-layout column class="ma-0 pa-3 mt-3">
        <v-layout class="ma-0">
            <v-img v-ripple style="cursor: pointer; border-radius: 5px;" max-width="100" max-height="100"
                   :src="user.profile.avatar" @click="$avatar.dialog = true"></v-img>
            <v-layout column class="ma-0 px-4">
                <div class="title font-weight-bold">{{ user.username }}</div>
                <div class="subtitle-2">{{ user.email }}</div>
            </v-layout>
        </v-layout>

        <v-dialog v-model="$avatar.dialog" max-width="400">
            <v-card class="background3">
                <v-layout class="ma-0 pa-4" justify-center>
                    <v-img max-height="300" max-width="300" a :src="user.profile.avatar"></v-img>
                </v-layout>
                <v-row no-gutters row style="max-height: 200px; overflow-y: auto">
                    <v-col cols="3" md="2" v-for="(el,index) in $avatar.list" :key="index">
                        <v-img class="ma-2" @click="saveAvatar(el)"
                               :src="el.path"></v-img>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>

export default {
    name: "UserInfo",
    components: {},
    data: () => ({
        dialog: false,
        parol: null
    }),
    computed: {
        user() {
            return this.$auth.user
        }
    },
    methods: {
        saveAvatar(avatar) {
            this.$auth.user.profile.avatar = avatar.path
            this.$user.updateAvatar(avatar.id)
        },
    },
    watch: {
        '$avatar.dialog'(val) {
            if (val && !this.$avatar.list.length)
                this.$avatar.loadList()
        }
    },
}
</script>

<style scoped>

</style>