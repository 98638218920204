<template>
    <v-row no-gutters align="center">
        <div>
            <air-text-field icon="search" placeholder="Поиск" v-model="$project.passwordSearch"></air-text-field>
        </div>
        <v-spacer></v-spacer>
        <air-button floating>Добавить группу</air-button>
        <air-button @click="openDialog" floating>Создать</air-button>
    </v-row>
</template>

<script>
export default {
    name: "PasswordsActions",
    data: () => ({
        dialog: false,
        showPassword: true
    }),
    methods: {
        openDialog() {
            this.$password.setItemFromDefault()
            setTimeout(() => {
                this.$password.dialog = true
            }, 50)
        },


    },
}
</script>

<style scoped>

</style>