<template>
    <air-dialog title="Работа с группой паролей" v-model="$passwordGroup.dialog" v-if="item">
        <v-layout column class="ma-0">
            <air-text-field v-model="item.name" icon="signature" placeholder="Наименование"></air-text-field>
            <icons class="mt-3" v-model="item.icon"></icons>
            <air-button floating class="mt-5" @click="save" block>Сохранить</air-button>
            <air-button danger transparent class="mt-3" @click="deleteItem" block>Удалить</air-button>
        </v-layout>
    </air-dialog>
</template>

<script>
import Icons from "@/views/projects/item/passwords/passwordGroup/Icons";

export default {
    name: 'PasswordGroupDialog',
    components: {
        Icons
    },
    data: () => ({}),
    methods: {
        save() {
            const {promise, created} = this.$passwordGroup.updateOrCreate()
            promise.then(res => {
                this.$project.loadItem(this.$project.item.id)
                this.$passwordGroup.dialog = false
            })
        },
        deleteItem() {
            this.$passwordGroup.destroy().then(() => {
                this.$passwordGroup.dialog = false
                this.$project.loadItem(this.$project.item.id)
            })
        },
    },
    computed: {
        item() {
            return this.$passwordGroup.item
        }
    }
}
</script>

<style scoped>

</style>