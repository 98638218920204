<template>
    <v-col class="pa-0">
        <air-list-template
            :search-function="(val) => val.child_project.name"
            v-model="$projectRelation.filters.search" :items="$project.item.child_projects">
            <template v-slot:actions>
            </template>
            <template v-slot:item="{item}">
                <v-col cols="6" md="4">
                    {{ item.child_project.name }}
                </v-col>
                <v-col cols="12" md="4" offset-md="4">
                    <v-row no-gutters align="center" justify="space-between">
                        <password-view :password="item"></password-view>
                        <password-actions :show-settings="false" :password="item"></password-actions>
                    </v-row>
                </v-col>
            </template>
        </air-list-template>
    </v-col>
</template>

<script>
import AirListTemplate from "@/components/template/AirListTemplate";
import PasswordActions from "@/views/projects/item/passwords/listElement/PasswordActions";
import PasswordView from "@/views/projects/item/passwords/listElement/PasswordView";

export default {
    name: 'ChildProjects',
    components: {
        AirListTemplate,
        PasswordActions,
        PasswordView
    },
    data: () => ({}),
    methods: {}
}
</script>

<style scoped>

</style>