const crypto = require('../crypto')

const getCredentials = uuid => {
    const password = localStorage.getItem(uuid)
    const hash = password ? crypto.hash(password) : null
    return {
        uuid, password, hash
    }
}

const generateCredentials = (password, uuid) => {
    if (!uuid)
        uuid = crypto.uuid4()
    password = crypto.hmac(uuid, password)
    const hash = crypto.hash(password)
    return {
        uuid, password, hash
    }
}

module.exports = {
    getCredentials, generateCredentials
}