<template>
    <div
        style="cursor:pointer;"
        @click="copyPassword(password)" :style="!password.decrypted ? 'font-size: 25px' : ''">
        {{
            password.decrypted ?
                password.decrypted : '••••••'
        }}
    </div>
</template>

<script>
export default {
    name: "PasswordView",
    props: {
        password: Object
    },
    methods: {
        copyPassword(el) {
            if (el.decrypted) {
                window.copy(el.decrypted)
                this.$snackbar.success('Пароль скопирован')
            } else {
                let credentials = this.$project.getCredentials(this.$project.item.uuid)
                if (credentials.password) {
                    window.copy(this.$crypto.decrypt(el.password, credentials.password))
                    this.$snackbar.success('Пароль скопирован')
                }
            }
        },
    }
}
</script>

<style scoped>

</style>