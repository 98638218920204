<template>
    <v-row v-if="item">
        <v-col cols="12" sm="8" md="6" lg="4">
            <air-text-field class="mb-5" icon="signature" v-model="name"
                            placeholder="Наименование"></air-text-field>
            <air-select v-if="$projectGroup.list.length" v-model="item.project_group"
                        :items="$projectGroup.list"></air-select>
            <air-button @click="updateProject" block floating class="mt-7">Сохранить</air-button>
            <air-button :loading="loading" @click="loadData" block floating class="mt-7">Выгрузить данные</air-button>
            <exit-from-project class="mt-6"></exit-from-project>
        </v-col>
    </v-row>
</template>

<script>
import ExitFromProject from "@/views/projects/ExitFromProject";

export default {
    name: "Settings",
    components: {
        ExitFromProject
    },
    data: () => ({
        projectGroup: null,
        name: '',
        loading: false
    }),
    methods: {
        loadData() {
            this.loading = true
            let passwordGroups = []
            let notes = []
            let childProjects = []
            const credentials = this.$project.getCredentials(this.$project.item.uuid)
            this.$project.item.password_groups.forEach(group => {
                const currentGroup = {name: group.name, passwords: []}
                group.passwords.forEach(val => {
                    const decrypted = this.$crypto.decrypt(val.password, credentials.password)
                    const desc = val.encrypt_description ? this.$crypto.decrypt(val.description, credentials.password) : val.description
                    currentGroup.passwords.push({
                        name: val.name,
                        address: val.address,
                        login: val.login,
                        password: decrypted,
                        description: desc
                    })
                })
                passwordGroups.push(currentGroup)
            })
            this.$project.item.notes.forEach(val => {
                const decrypted = this.$crypto.decrypt(val.text, credentials.password)
                notes.push({
                    name: val.name,
                    text: decrypted
                })
            })

            this.$project.item.child_projects.forEach(val => {
                const decrypted = this.$crypto.decrypt(val.password, credentials.password)
                childProjects.push({name: val.child_project.name, password: decrypted})
            })

            const results = {
                passwordGroups: passwordGroups,
                notes: notes
            }
            if (this.$project.item.personal)
                results.childProjects = childProjects
            const FileSaver = require('file-saver')
            const blob = new Blob([JSON.stringify(results)], {type: 'application/json'});
            FileSaver.saveAs(blob, 'export_data.json')
            this.loading = false
        },
        updateProject() {
            let credentials = this.$project.getCredentials(this.item.uuid)
            this.$project.update({
                id: this.item.id,
                name: this.name,
                project_group: this.item.project_group,
                hash: credentials.hash
            }, {setToModel: false}).then((res) => {
                this.$project.item.name = res.name
                this.$snackbar.success('Данные сохранены')
            })
        },
    },
    computed: {
        item() {
            return this.$project.item
        }
    },
    watch: {},
    created() {
        this.name = this.item.name
    }
}
</script>

<style scoped>

</style>