<template>
    <div class="title my-5">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'AirTitleText',
    data: () => ({}),
    methods: {}
}
</script>

<style scoped>

</style>