<template>
    <vs-dialog not-padding
               :not-close="notClose"
               :value="value"
               :loading="loading"
               :blur="blur"
               :prevent-close="notClose"
               @close="close"
    >
        <v-col class="pa-0">
            <air-card :title="title" :description="description">
                <template v-slot:title v-if="$slots.title">
                    <slot name="title"></slot>
                </template>
                <template v-slot:description v-if="$slots.description">
                    <slot name="description"></slot>
                </template>
                <template v-slot:actions v-if="$slots.actions">
                    <slot name="actions"></slot>
                </template>
                <template v-slot:default v-if="$slots.default">
                    <slot></slot>
                </template>
            </air-card>
        </v-col>
    </vs-dialog>
</template>

<script>
import AirCard from "./AirCard";

export default {
    name: 'AirDialog',
    components: {
        AirCard
    },
    props: {
        value: Boolean,
        loading: Boolean,
        blur: Boolean,
        notClose: Boolean,
        title: String,
        description: String,
    },
    methods: {
        close() {
            this.$emit('input', false)
            this.$emit('close')
        }
    },
    watch: {},
    created() {

    }
}
</script>

<style>

</style>