<template>
    <div class="pr-0" v-if="$project.item.decrypted">
        <v-row no-gutters align="center" justify="end" class="flex-nowrap">
            <air-button
                class="pa-0 ma-0"
                transparent
                @click="changePasswordView()"
                :icon="password.decrypted ? 'eye' : 'eye-slash'"></air-button>
            <air-button
                class="pa-0 ma-0"
                transparent
                @click="copyFull"
                icon="copy"></air-button>
            <air-button
                v-if="showSettings && $project.hasRight('EDIT_PASSWORDS')"
                class="pa-0 ma-0"
                transparent
                @click="$password.openDialog(password)"
                icon="ellipsis-v"></air-button>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "PasswordActions",
    props: {
        password: Object,
        showSettings: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        changePasswordView() {
            let credentials = this.$project.getCredentials(this.$project.item.uuid)
            if (this.password.decrypted) {
                this.password.decrypted = null
                this.password.decrypted_description = null
            } else {
                this.password.decrypted = this.$crypto.decrypt(this.password.password, credentials.password)
                this.password.decrypted_description = this.$crypto.decrypt(this.password.description, credentials.password)
            }
        },
        copyFull() {
            let credentials = this.$project.getCredentials(this.$project.item.uuid)
            const decrypted = this.$crypto.decrypt(this.password.password, credentials.password)
            window.copy(`Наименование: ${this.password.name}\nАдрес: ${this.password.address}\nЛогин: ${this.password.login}\nПароль: ${decrypted}`)
            this.$snackbar.success('Данные скопированы')
        }
    }
}
</script>

<style scoped>

</style>