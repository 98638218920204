import { render, staticRenderFns } from "./ProjectUserDialog.vue?vue&type=template&id=2135a8f6&scoped=true"
import script from "./ProjectUserDialog.vue?vue&type=script&lang=js"
export * from "./ProjectUserDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2135a8f6",
  null
  
)

export default component.exports