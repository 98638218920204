<template>
    <v-row no-gutters align="center" class="py-2 body-2" style="min-height: 50px">
        <slot></slot>
    </v-row>
</template>

<script>
export default {
    name: 'AirListElementTemplate',
    data: () => ({}),
    methods: {}
}
</script>

<style scoped>

</style>