<template>
    <v-layout class="ma-0" column>
        <v-layout class="ma-0 mt-5 mb-3" align-center>
            <air-title-text class="mb-0 mt-0">Список проектов</air-title-text>
        </v-layout>
        <air-list-template v-model="$project.filters.search" :items="$projectGroup.list" child-field="projects">
            <template v-slot:actions>
                <v-spacer></v-spacer>
                <project-create-dialog></project-create-dialog>
                <air-button floating icon="folder-plus" @click="$projectGroup.openDialog"></air-button>
                <project-group-dialog></project-group-dialog>
            </template>
            <template v-slot:item="{item}">
                <v-row no-gutters align="center" class="noselect flex-nowrap">
                    <v-row class="pointer" no-gutters align="center" @click="toggleShow(item)">
                        <air-icon :icon="item.icon" class="mr-3 primary--text"></air-icon>
                        <div>{{ item.name }}</div>
                        <div class="ml-2 caption grey--text">{{ item.projects.length }}</div>
                        <air-icon :icon="!item.hidden ? 'caret-up' : 'caret-down'"
                                  class="ml-2 grey--text"></air-icon>
                    </v-row>
                    <air-button
                        v-if="item.id"
                        icon="ellipsis-v"
                        @click="item.id ? $projectGroup.openDialog(item) : null"
                        class="mx-0 my-0"
                        transparent></air-button>
                </v-row>
            </template>
            <template v-slot:child="{child}">
                <router-link :to="{name: 'projectPasswords', params: {projectId: child.id}}" style="width: 100%">
                    <v-row no-gutters align="center" class="flex-nowrap mr-3">
                        <div>{{ child.name }}</div>
                        <v-spacer></v-spacer>
                        <v-row justify="end" align="center" v-if="child.users.length > 1" no-gutters>
                            <div class="grey--text caption">{{ child.users.length }}</div>
                            <air-icon icon="user" color="primary" class="ml-2"></air-icon>
                        </v-row>
                    </v-row>
                </router-link>
            </template>
        </air-list-template>
    </v-layout>
</template>

<script>
import ProjectCreateDialog from "@/views/projects/list/ProjectCreateDialog";
import ProjectGroupDialog from "@/views/projects/list/ProjectGroupDialog";
import AirListTemplate from "@/components/template/AirListTemplate";

export default {
    name: "Projects",
    data: () => ({}),
    components: {
        ProjectCreateDialog,
        ProjectGroupDialog,
        AirListTemplate
    },
    methods: {
        toggleShow(item) {
            item.hidden = !item.hidden
            let openProjectGroups = this.$projectGroup.getOpenProjectGroups()
            const index = openProjectGroups.indexOf(item.id)
            if (!item.hidden && index === -1) openProjectGroups.push(item.id)
            if (item.hidden && index > -1) openProjectGroups.splice(index, 1)
            this.$projectGroup.setOpenProjectGroups(openProjectGroups)
        }
    },
    created() {
        // if (!this.$projectGroup.list.length)
        this.$projectGroup.loadList()
    },
    watch: {},
    computed: {},
}
</script>

<style scoped>

</style>