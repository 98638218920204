const snackbar = {
    title: null,
    text: null,
    color: null,
    value: false,
    position: null,
    settings: {
        position: 'top-right',
        success: 'success',
        fail: 'danger'
    },
    fail: (text = null, title = null) => {
        snackbar.set(text, title, snackbar.settings.fail)
    },
    success: (text = null, title = null) => {
        snackbar.set(text, title, snackbar.settings.success)
    },
    set: (text = null, title = null, color) => {
        snackbar.position = snackbar.settings.position
        snackbar.text = text
        snackbar.title = title
        snackbar.color = color
        snackbar.value = true
        setTimeout(() => {
            snackbar.value = false
        }, 50)
    }
}

module.exports = snackbar