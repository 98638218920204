<template>
    <air-dialog :value="value"
                title="Приглашение пользователя"
                @close="$emit('input', false)">
        <v-col class="pa-0" v-if="item">
            <v-menu v-if="!item.to_user" offset-y v-model="menu" nudge-bottom="8">
                <template v-slot:activator="{ on }">
                    <v-row no-gutters v-on="on">
                        <air-text-field placeholder="Логин пользователя" icon="user"
                                        v-model="$user.filters.search"></air-text-field>
                    </v-row>
                </template>
                <v-card>
                    <v-list class="pa-0" dense>
                        <v-list-item
                            class="py-2"
                            v-for="(el, index) in $user.list"
                            :key="index"
                            @click="item.to_user = el">
                            <v-img :src="el.profile.avatar" max-width="35" max-height="35"></v-img>
                            <v-list-item-content class="pl-4">
                                <v-list-item-title v-text="el.username"></v-list-item-title>
                            </v-list-item-content>

                        </v-list-item>
                    </v-list>
                </v-card>
            </v-menu>

            <v-col class="pa-0" v-else>
                <v-row no-gutters align="center" class="mb-5">
                    <v-img max-width="40" max-height="40" :src="item.to_user.profile.avatar"></v-img>
                    <div class="px-3">{{ item.to_user.username }}</div>
                    <air-icon v-if="item.admin" icon="crown" class="orange--text"></air-icon>
                    <v-spacer></v-spacer>
                    <air-button floating icon="times" @click="item.to_user = null"></air-button>
                </v-row>
                <v-spacer></v-spacer>
                <vs-checkbox v-model="item.admin">
                    Администратор
                </vs-checkbox>
                <v-col class="pa-0" v-if="!item.admin">
                    <air-divider class="my-2"></air-divider>
                    <vs-checkbox :val="el" v-model="item.rights" v-for="(el, index) in $projectRight.list"
                                 :key="index">
                        {{ el.title }}
                    </vs-checkbox>
                </v-col>
            </v-col>
            <air-button @click="sendInvite" floating class="mt-7" block :disabled="!item.to_user">Пригласить
            </air-button>
        </v-col>
    </air-dialog>
</template>

<script>
import SelectRights from "./SelectRights";

export default {
    name: "InviteUser",
    props: {
        value: Boolean
    },
    components: {
        SelectRights
    },
    data: () => ({
        menu: false,
        timeout: null,
    }),
    watch: {
        '$user.filters.search'(val) {
            clearTimeout(this.timeout)
            setTimeout(() => {
                if (!val) {
                    this.menu = false
                    return
                }
                this.$user.loadList(
                    {page_size: 5, project: this.project.id})
                    .then(res => {
                        this.menu = res.length > 0;
                    })
            }, 300)
        },
        value(val) {
            if (val) {
                this.$invite.setItemFromDefault()
            }
        }
    },
    methods: {
        sendInvite() {
            this.menu = false
            this.$project.sendInvite(this.item).then(res => {
                this.$snackbar.success('Приглашение отправлено')
                this.$project.item.project_invites.push(res)
            }).catch(() => {
                this.$snackbar.fail('Не удалось отправить приглашение')
            })
            this.$emit('input', false)
        }
    },
    computed: {
        project() {
            return this.$project.item
        },
        item() {
            return this.$invite.item
        }
    }
}
</script>

<style scoped>

</style>