<template>
    <auth-view title="Регистрация">
        <air-form v-model="valid">
            <air-text-field
                :rules="[$rules.required]"
                v-model="username" placeholder="Логин" icon="signature"></air-text-field>
            <air-text-field
                :rules="[$rules.required, $rules.email]"
                class="mt-5" v-model="email" placeholder="Почта" icon="envelope"></air-text-field>
            <air-text-field type="password"
                            :rules="[$rules.required]"
                            class="mt-5" v-model="password" placeholder="Пароль" icon="key"></air-text-field>
            <air-text-field type="password"
                            :rules="[$rules.required, $rules.match(password)]"
                            class="mt-5" v-model="password_confirmation" placeholder="Подтвердите пароль"
                            icon="redo-alt"></air-text-field>
            <v-row no-gutters align="center" justify="center" class="flex-column">
                <air-button
                    :disabled="!valid"
                    block
                    :loading="loading" @click="register"
                    class="mt-7" gradient floating style="min-width: 200px">Регистрация
                </air-button>
                <router-link :to="{name: 'login'}">
                    <div class="mt-3 body-2">Войти</div>
                </router-link>
            </v-row>
        </air-form>
    </auth-view>
</template>

<script>
import crypto from "../../mixins/crypto";
import AuthView from "@/views/auth/AuthView";

export default {
    name: "Register",
    mixins: [
        crypto
    ],
    components: {
        AuthView
    },
    data: () => ({
        username: '',
        password: '',
        password_confirmation: '',
        email: '',
        valid: false,
        loading: false,
        errors: [],
    }),
    methods: {
        register() {
            this.loading = true
            let credentials = this.$project.generateCredentials(this.password)
            this.$auth.register({
                username: this.username,
                password: credentials.hash,
                uuid: credentials.uuid,
                email: this.email
            }).then((response) => {
                localStorage.setItem(credentials.uuid, credentials.password)
                this.$router.push({
                    name: 'projectList'
                })
            }).catch(e => {
                this.loading = false
                this.$snackbar.fail('Ошибка регистрации')
            })
        }
    }
}
</script>

<style scoped>

</style>