import { render, staticRenderFns } from "./AirDivider.vue?vue&type=template&id=0154b691&scoped=true"
import script from "./AirDivider.vue?vue&type=script&lang=js"
export * from "./AirDivider.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0154b691",
  null
  
)

export default component.exports