<template>
    <v-col class="pa-0">
        <air-title-text class="mb-2 mt-5">Профиль</air-title-text>
        <profile-actions></profile-actions>
        <air-divider class="mb-4 mt-2"></air-divider>
        <router-view></router-view>
        <v-footer app class="px-6 py-4 caption">
            <v-row no-gutters align="center">
                <div class="align-content-center">© repass 2021</div>
                <v-spacer></v-spacer>
                <a href="https://t.me/bubaley" target="_blank">
                    <air-button icon-size="18" class="ma-0 pa-0"
                                icon="fab fa-telegram"
                                transparent></air-button>
                </a>
                <a href="https://github.com/" target="_blank">
                    <air-button icon-size="18" class="pa-0 ma-0" icon="fab fa-github" transparent></air-button>
                </a>
            </v-row>
        </v-footer>
    </v-col>
</template>

<script>
import ProfileActions from "@/views/user/ProfileActions";

export default {
    name: "Profile",
    components: {
        ProfileActions
    }
}
</script>

<style scoped>

</style>